import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../common/components/Layout";
import Section from "../../../common/components/Section";
import MyPageNavigationBar from "../components/MyPageNavigationBar";
import { SCREENS } from "../../../../lib/route/utils/router";

function MyPageContainer() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname === SCREENS.MY_PAGE) {
      navigate(SCREENS.MY_PAGE + SCREENS.MY_USER_PROFILE, { replace: true });
    }
  }, [location?.pathname]);

  return (
    <Layout>
      <Section>
        <MyPageNavigationBar />
        <Outlet />
      </Section>
    </Layout>
  );
}

export default MyPageContainer;
