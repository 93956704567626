import React from "react";
import Flex from "./Flex";
import Text from "./Text";
import theme from "../../../lib/theme";

export interface TabsProps {
  tabs: {
    text?: string;
    selected?: boolean;
    onClick?: () => void;
  }[];
  size?: "lg" | "sm";
}

function Tabs({ tabs, size = "sm" }: TabsProps) {
  const padding =
    size === "sm"
      ? `${theme.spacing[16]}px ${theme.spacing[8]}px`
      : theme.spacing[8];

  return (
    <Flex
      width="100%"
      alignItems="stretch"
      borderBottom={`1px solid ${theme.color.neutral800}`}
    >
      {tabs?.map((tab) => {
        const { text, selected, onClick } = tab;
        return (
          <Flex
            key={text}
            flex={1}
            maxWidth="50%"
            alignItems="center"
            justifyContent="center"
            padding={padding}
            backgroundColor={
              selected ? theme.color.primary1 : theme.color.neutralWhite
            }
            cursor="pointer"
            onClick={onClick}
          >
            <Text
              typography={size === "lg" ? "heading5" : "label3"}
              fontWeight={selected ? "bold" : "normal"}
              color={
                selected ? theme.color.neutralWhite : theme.color.neutral800
              }
              textAlign="center"
            >
              {text}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
}

export default Tabs;
