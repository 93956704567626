import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserType } from "../../../../lib/apollo/graphql/generated";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import Flex from "../../../common/components/Flex";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";

function SignUpStepper() {
  const { isMobile } = useScreenWidthContext();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const userType = params?.userType;

  const step = pathname.split("step")?.[1];
  const isStep1 = step === "1";

  const steps = [t("signUp.step1"), t("signUp.step2")];

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      marginBottom={theme.spacing[isMobile ? 16 : 48]}
    >
      <Text
        typography={isMobile ? "heading3" : "heading2"}
        color={theme.color.neutral900}
        marginBottom={theme.spacing[24]}
      >
        {t("signUp.signUpTitle")}
      </Text>

      <Text
        typography={isMobile ? "body1Bold" : "heading3"}
        color={theme.color.neutral900}
        marginBottom={theme.spacing[isMobile ? 16 : 48]}
        textAlign="center"
      >
        {`${t("signUp.signUpIntroTitle1")}${isMobile ? "\n" : " "}${t(
          "signUp.signUpIntroTitle2"
        )}`}
      </Text>

      {isStep1 && (
        <Text
          typography="body1"
          color={theme.color.neutral900}
          marginBottom={theme.spacing[isMobile ? 16 : 48]}
        >
          {t("signUp.alreadyRegistered")}{" "}
          <strong
            style={{ cursor: "pointer" }}
            onClick={() => navigate(SCREENS.SIGN_IN)}
          >
            {t("signUp.signIn")}
          </strong>
        </Text>
      )}

      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom={theme.spacing[10]}
      >
        {steps?.map((stepText, index) => {
          const isCurrent = index + 1 === Number(step);
          const showLeftDivider = index !== 0;
          const showRightDivider = index !== steps?.length - 1;

          return (
            <Flex
              key={stepText + index}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex={1}
              position="relative"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                width={isMobile ? 48 : 64}
                height={isMobile ? 48 : 64}
                borderRadius={(isMobile ? 48 : 64) / 2}
                backgroundColor={
                  isCurrent ? theme.color.primary2 : theme.color.neutralWhite
                }
                border={`1px solid ${theme.color.primary2}`}
              >
                <Text
                  typography="heading2"
                  color={
                    isCurrent ? theme.color.neutralWhite : theme.color.primary2
                  }
                >
                  {index + 1}
                </Text>
              </Flex>
              {!!showRightDivider && (
                <div
                  style={{
                    position: "absolute",
                    height: 1,
                    backgroundColor: "#616161",
                    width: "50%",
                    left: "50%",
                    top: "50%",
                    zIndex: -1,
                  }}
                />
              )}
              {!!showLeftDivider && (
                <div
                  style={{
                    position: "absolute",
                    height: 1,
                    backgroundColor: "#616161",
                    width: "50%",
                    left: 0,
                    top: "50%",
                    zIndex: -1,
                  }}
                />
              )}
            </Flex>
          );
        })}
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" width="100%">
        {steps?.map((stepText, index) => {
          const isCurrent = index + 1 === Number(step);

          return (
            <Text
              flex={1}
              key={stepText}
              typography={isCurrent ? "body2Bold" : "body2"}
              color={theme.color.primary2}
              textAlign="center"
            >
              {stepText}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default SignUpStepper;
