import React, { useState } from "react";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";
import Text from "../../../common/components/Text";
import Tabs from "../../../common/components/Tabs";
import { SEARCH_MEDICINE_TAB } from "../../../../utils/constants";
import Input from "../../../common/components/Input";
import Button from "../../../common/components/Button";
import axios from "axios";
import Divider from "../../../common/components/Divider";

const SEARCH_URL =
  "http://apis.data.go.kr/1471000/DrbEasyDrugInfoService/getDrbEasyDrugList";

const KEY =
  "m1w7x6m32wS4rZ2SFkBXJrs/aMY3wMTBAW1/v3RLQkZqoVLrCCTB4AEU10vrqYqIAJYeORNAo/NLqWadXDK8BQ==";

interface SearchMedicineModalProps {
  onMedicineSearched: (text: string) => void;
  onClose?: () => void;
}

function SearchMedicineModal({
  onMedicineSearched,
  onClose,
}: SearchMedicineModalProps) {
  const { t } = useTranslation();

  const [tab, setTab] = useState(SEARCH_MEDICINE_TAB.BY_NAME);
  const [query, setQuery] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [searchedData, setSearchedData] = useState<any[] | null>(null);

  async function onSearchClick() {
    const params: any = {
      ServiceKey: KEY,
      pageNo: 1,
      numOfRows: 100,
      type: "json",
    };

    if (tab === SEARCH_MEDICINE_TAB.BY_NAME) {
      params.itemName = query;
    } else {
      params.entpName = query;
    }

    const { data } = await axios({
      method: "GET",
      url: SEARCH_URL,
      params,
    });

    console.log(data);

    setSearchedData(data?.body?.items);

    if (!isSearched) {
      setIsSearched(true);
    }
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      gap={theme.spacing[32]}
    >
      <Text typography="heading3" color={theme.color.neutral800}>
        {t("searchMedicine.title")}
      </Text>
      <Tabs
        tabs={[
          {
            text: t("searchMedicine.byName"),
            selected: tab === SEARCH_MEDICINE_TAB.BY_NAME,
            onClick: () => setTab(SEARCH_MEDICINE_TAB.BY_NAME),
          },
          {
            text: t("searchMedicine.byCompany"),
            selected: tab === SEARCH_MEDICINE_TAB.BY_COMPANY,
            onClick: () => setTab(SEARCH_MEDICINE_TAB.BY_COMPANY),
          },
        ]}
        size="lg"
      />
      <Input
        label={
          tab === SEARCH_MEDICINE_TAB.BY_NAME
            ? t("searchMedicine.byNameLabel")
            : t("searchMedicine.byCompanyLabel")
        }
        placeholder={
          tab === SEARCH_MEDICINE_TAB.BY_NAME
            ? t("searchMedicine.byNamePlaceholder")
            : t("searchMedicine.byCompanyPlaceholder")
        }
        value={query}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setQuery(e?.target?.value)
        }
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e?.key === "Enter") {
            onSearchClick();
          }
        }}
      />

      <Button fullWidth text={t("common.search")} onClick={onSearchClick} />

      {isSearched && (
        <Flex
          flexDirection="column"
          width="100%"
          paddingLeft={theme.spacing[12]}
          paddingRight={theme.spacing[12]}
          gap={theme.spacing[8]}
        >
          <Text typography="heading3" color={theme.color.neutral800}>
            {t("common.searchResult")}
          </Text>
          <Divider full size={1} />
          {searchedData && searchedData?.length > 0 ? (
            searchedData?.map((item) => (
              <Flex
                key={item?.itemSeq}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                gap={theme.spacing[8]}
              >
                <Flex
                  flexDirection="column"
                  flex={1}
                  position="relative"
                  overflow="hidden"
                >
                  <Text typography="body1" width="100%" overflow="hidden">
                    {item?.itemName}
                  </Text>
                  <Text typography="label2" color={theme.color.neutral500}>
                    {item?.entpName}
                  </Text>
                </Flex>

                <Button
                  text={t("common.select")}
                  size="md"
                  variant="outline"
                  fontColor="primary1"
                  bgColor="neutralWhite"
                  onClick={() => {
                    onMedicineSearched(item?.itemName + " " + item?.entpName);
                    onClose!();
                  }}
                />
              </Flex>
            ))
          ) : (
            <Text typography="body1">{t("common.searchNoResult")}</Text>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default SearchMedicineModal;
