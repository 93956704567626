import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { ReactChildrenProp } from "global";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../../lib/firebase";
import {
  Gender,
  useGetMyUserProfileLazyQuery,
  User,
  UserType,
} from "../../../lib/apollo/graphql/generated";
import { Outlet, useNavigate } from "react-router-dom";
import SessionProvider from "./SessionProvider";
import { SCREENS } from "../../../lib/route/utils/router";

interface AuthState {
  initializing: boolean;
  firebaseUser: FirebaseUser | null;
  profile?: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    isSubscriber?: boolean | null;
    usuableTickets: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: { __typename?: "Profile"; id: string } | null;
    instructor?: { __typename?: "Instructor"; id: string } | null;
    interpreter?: { __typename?: "Interpreter"; id: string } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      name: string;
    } | null> | null;
  } | null;
}

const defaultValue: AuthState = {
  initializing: true,
  firebaseUser: null,
  profile: null,
};

const AuthContext = createContext(defaultValue);

function AuthProvider() {
  const navigate = useNavigate();

  const [state, setState] = useState({ ...defaultValue });

  const [getMyUserProfile, { loading, data }] = useGetMyUserProfileLazyQuery();

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (!state?.initializing) {
        setState((prev) => ({
          ...prev,
          initializing: true,
        }));
      }

      if (firebaseUser) {
        const { data } = await getMyUserProfile({
          fetchPolicy: "network-only",
        });

        setState((prev) => ({
          ...prev,
          firebaseUser,
          profile: data?.getMyUserProfile,
          initializing: false,
        }));
      } else {
        setState((prev) => ({
          ...defaultValue,
          initializing: false,
        }));
      }
    });
  }, []);

  if (state?.initializing || loading) return <div />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        profile: state?.firebaseUser ? data?.getMyUserProfile : null,
      }}
    >
      {state?.profile ? (
        <SessionProvider>
          <Outlet />
        </SessionProvider>
      ) : (
        <Outlet />
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuthContext() {
  return useContext(AuthContext);
}
