import React from "react";
import Flex from "../../../common/components/Flex";
import PHRPathname from "../../phr/component/PHRPathname";
import PHRAddButton from "../../phr/component/PHRAddButton";
import useAllergyContainer from "./AllergyContainer.hook";
import Loading from "../../../common/components/Loading";
import Button from "../../../common/components/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AllergyForm from "../components/AllergyForm";

function AllergyContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data },
  } = useAllergyContainer();

  if (loading) return <Loading />;

  return (
    <>
      <PHRPathname name="allergyTitle" />
      <AllergyForm allergy={null} />

      {data?.map((allergy) => (
        <AllergyForm key={allergy?.id} allergy={allergy!} />
      ))}
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Button
          text={t("common.save")}
          size="lg"
          round={false}
          onClick={() => toast(t("common.saved"))}
        />
      </Flex>
    </>
  );
}

export default AllergyContainer;
