import { useEffect } from "react";
import {
  UpdatedOnlineInstructorDocument,
  UpdatedOnlineInstructorSubscription,
  UpdatedOnlineInstructorSubscriptionVariables,
  useGetOnlineInstructorsQuery,
} from "../../../lib/apollo/graphql/generated";

function useHomeOnlineInstructors() {
  const { loading, data, subscribeToMore } = useGetOnlineInstructorsQuery({});

  useEffect(() => {
    const unsubscribeOnlineInstructors = subscribeToMore<
      UpdatedOnlineInstructorSubscription,
      UpdatedOnlineInstructorSubscriptionVariables
    >({
      document: UpdatedOnlineInstructorDocument,
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newInstructor = subscriptionData?.data?.updatedOnlineInstructor;

          if (!newInstructor) return prev;

          const prevInstructors = prev?.getOnlineInstructors || [];

          const newOnlineStatus = newInstructor?.online;

          let editedInstructors = [...prevInstructors];

          if (newOnlineStatus) {
            editedInstructors = [newInstructor, ...prevInstructors];
          } else {
            editedInstructors = [...prevInstructors].filter(
              (instructor) => instructor?.id !== newInstructor?.id
            );
          }

          editedInstructors = [...editedInstructors]?.filter((obj, index) => {
            return (
              index === editedInstructors.findIndex((o) => obj?.id === o?.id)
            );
          });

          return {
            getOnlineInstructors: editedInstructors,
          };
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      unsubscribeOnlineInstructors();
    };
  }, []);

  return {
    models: {
      loading,
      data: data?.getOnlineInstructors,
    },
  };
}

export default useHomeOnlineInstructors;
