import React, { useEffect, useRef } from "react";
import { createBrowserRouter, Outlet, useNavigate } from "react-router-dom";
import SignInContainer from "../../../modules/auth/signIn/container/SignInContainer";
import SignUpIntroContainer from "../../../modules/auth/signUp/signUpIntro/container/SignUpIntroContainer";
import SignUpStep1Container from "../../../modules/auth/signUp/signUpStep1/container/SignUpStep1Container";
import SignUpStep2Container from "../../../modules/auth/signUp/signUpStep2/container/SignUpStep2Container";
import SignUpStep3Container from "../../../modules/auth/signUp/signUpStep3/container/SignUpStep3Container";
import HomeContainer from "../../../modules/home/container/HomeContainer";
import MyPageContainer from "../../../modules/myPage/myPage/container/MyPageContainer";
import MyPassesContainer from "../../../modules/myPage/myPasses/container/MyPassesContainer";
import MyProfileContainer from "../../../modules/myPage/myProfile/container/MyProfileContainer";
import MyUserProfileContainer from "../../../modules/myPage/myUserProfile/container/MyUserProfileContainer";
import PurchaseContainer from "../../../modules/purchase/purchase/container/PurchaseContainer";
import PurchaseDoneContainer from "../../../modules/purchase/purchaseDone/container/PurchaseDoneContainer";
import PurchaseItemsContainer from "../../../modules/purchase/purchaseItems/container/PurchaseItemsContainer";
import SessionContainer from "../../../modules/session/container/SessionContainer";
import AuthProvider from "../../../modules/common/provider/AuthProvider";
import PHRContainer from "../../../modules/phr/phr/container/PHRContainer";
import ChronicDiseaseContainer from "../../../modules/phr/chronicDisease/container/ChronicDiseaseContainer";
import AllergyContainer from "../../../modules/phr/allergy/container/AllergyContainer";
import MedicineContainer from "../../../modules/phr/medicine/container/MedicineContainer";
import VaccineContainer from "../../../modules/phr/vaccine/container/VaccineContainer";
import SurgeryContainer from "../../../modules/phr/surgery/container/SurgeryContainer";
import InsuranceContainer from "../../../modules/phr/insurance/container/InsuranceContainer";
import InstructorContainer from "../../../modules/instructor/container/InstructorContainer";
import MySubscriptionContainer from "../../../modules/myPage/mySubscription/container/MySubscriptionContainer";
import MyBillingContainer from "../../../modules/myPage/myBilling/container/MyBillingContainer";
import MySessionContainer from "../../../modules/myPage/mySession/container/MySessionContainer";
import SessionHistoryContainer from "../../../modules/sessionHistory/container/SessionHistoryContainer";
import KakaoCallbackContainer from "../../../modules/auth/kakao/container/KakaoCallbackContainer";

export const SCREENS = {
  HOME: "/",
  SIGN_IN: "/sign_in",
  SIGN_UP: "/sign_up",
  PURCHASE: "/purchase",
  ITEMS: "/items",
  SUBSCRIPTION: "/subscription",
  PASS: "/pass",
  PURCHASE_DONE: "/done",
  MY_PAGE: "/my_page",
  MY_USER_PROFILE: "/user",
  MY_PROFILE: "/profile",
  MY_PASSES: "/passes",
  MY_SUBSCRIPTION: "/subscription",
  MY_BILLINGS: "/billings",
  MY_SESSION_HISTORY: "/sessions",

  SESSION: "/session",

  PHR: "/phr",
  CHRONIC_DISEASE: "/chronic_disease",
  ALLERGIES: "/allergies",
  MEDICINE: "/medicine",
  VACCINATION: "/vaccination",
  SURGERY: "/surgery",
  INSURANCE: "/insurance",

  INSTRUCTOR: "/instructor",

  SESSION_HISTORY: "/history",

  POLICY: "/policy",

  TERMS: "/terms",

  KAKAO_CALLBACK: "/kakao_callback",
};

export const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: SCREENS.HOME,
        element: <HomeContainer />,
      },

      {
        path: SCREENS.SIGN_IN,
        element: <SignInContainer />,
      },
      {
        path: SCREENS.SIGN_UP,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.SIGN_UP + "/intro",
            element: <SignUpIntroContainer />,
          },
          {
            path: SCREENS.SIGN_UP + "/step1",
            element: <SignUpStep1Container />,
          },
          {
            path: SCREENS.SIGN_UP + "/step2",
            element: <SignUpStep2Container />,
          },
          {
            path: SCREENS.SIGN_UP + "/step3",
            element: <SignUpStep3Container />,
          },
        ],
      },
      {
        path: SCREENS.PHR,
        element: <PHRContainer />,
        children: [
          {
            path: SCREENS.PHR + SCREENS.CHRONIC_DISEASE,
            element: <ChronicDiseaseContainer />,
          },
          {
            path: SCREENS.PHR + SCREENS.ALLERGIES,
            element: <AllergyContainer />,
          },
          {
            path: SCREENS.PHR + SCREENS.MEDICINE,
            element: <MedicineContainer />,
          },
          {
            path: SCREENS.PHR + SCREENS.VACCINATION,
            element: <VaccineContainer />,
          },
          {
            path: SCREENS.PHR + SCREENS.SURGERY,
            element: <SurgeryContainer />,
          },
          {
            path: SCREENS.PHR + SCREENS.INSURANCE,
            element: <InsuranceContainer />,
          },
        ],
      },
      {
        path: SCREENS.PURCHASE,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.PURCHASE + SCREENS.ITEMS,
            element: <PurchaseItemsContainer />,
          },
          {
            path: SCREENS.PURCHASE + SCREENS.SUBSCRIPTION + "/:planId",
            element: <PurchaseContainer />,
          },
          {
            path: SCREENS.PURCHASE + SCREENS.PASS + "/:passId",
            element: <PurchaseContainer />,
          },
          {
            path: SCREENS.PURCHASE + SCREENS.PURCHASE_DONE,
            element: <PurchaseDoneContainer />,
          },
        ],
      },

      {
        path: SCREENS.MY_PAGE,
        element: <MyPageContainer />,
        children: [
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_USER_PROFILE,
            element: <MyUserProfileContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_PROFILE,
            element: <MyProfileContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_PASSES,
            element: <MyPassesContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_SUBSCRIPTION,
            element: <MySubscriptionContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_BILLINGS,
            element: <MyBillingContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_SESSION_HISTORY,
            element: <MySessionContainer />,
          },
        ],
      },

      {
        path: SCREENS.SESSION_HISTORY + "/:sessionId",
        element: <SessionHistoryContainer />,
      },

      {
        path: SCREENS.INSTRUCTOR + "/:instructorId",
        element: <InstructorContainer />,
      },

      {
        path: SCREENS.SESSION + "/:sessionId",
        element: <SessionContainer />,
      },

      {
        path: SCREENS.KAKAO_CALLBACK,
        element: <KakaoCallbackContainer />,
      },

      {
        path: SCREENS.TERMS,
        element: <Policy isTerm={true} />,
      },

      {
        path: SCREENS.POLICY,
        element: <Policy />,
      },
    ],
  },
]);

interface PolicyProps {
  isTerm?: boolean;
}

function Policy({ isTerm = false }: PolicyProps) {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.click();
    }
  }, [ref?.current]);

  function onClick() {
    if (isTerm) {
      window.open(
        "https://www.notion.so/2023-12-20-9e8e008f43814a1e892108fd8edb8fc0?pvs=4",
        "_blank"
      );
    } else {
      window.open(
        "https://www.notion.so/VMS-2023-12-20-32bec2a2aa314863b22dbe63db483224?pvs=4",
        "_blank"
      );
    }

    navigate(-1);
  }

  return <div ref={ref} onClick={onClick} />;
}
