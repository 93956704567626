import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import PHROptionSelector from "../../phr/component/PHROptionSelector";
import useSurgeryForm from "./SurgeryForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";
import dayjs from "dayjs";
import InputLabel from "../../../common/components/InputLabel";
import uploadIcon from "../../../../assets/icon/upload_icon.png";

export interface SurgeryFormProps {
  surgery: {
    __typename?: "UserSurgery";
    id: string;
    surgery?: string | null;
    part?: string | null;
    department?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    media?: {
      __typename?: "Media";
      id: string;
      uri: string;
      name: string;
    } | null;
  } | null;
}

function SurgeryForm({ surgery }: SurgeryFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    refs: { mediaInputRef },
    models: { state },
    operations: { onInputChange, onDeleteClick, onMediaChange, onAddClick },
  } = useSurgeryForm({ surgery });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      backgroundColor={
        !surgery ? theme.color.neutralWhite : theme.color.secondary3
      }
      padding={theme.spacing[8]}
    >
      {!surgery && <PHRAddButton text="surgeryTitle" onClick={onAddClick} />}
      {!!surgery && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <Input
        label={t("phr.surgery")}
        value={state?.surgery}
        placeholder={t("phr.surgeryPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "surgery")
        }
      />
      <Input
        label={t("phr.part")}
        value={state?.part}
        placeholder={t("phr.partPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "part")
        }
      />
      <Input
        label={t("phr.department")}
        value={state?.department}
        placeholder={t("phr.departmentPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "department")
        }
      />
      <Flex flexDirection="column" width="100%" gap={theme.spacing[8]}>
        <InputLabel label={t("phr.media")} />
        <Flex
          {...{
            width: "100%",
            padding: theme.spacing[12],
            border: `1px solid ${theme.color.neutral500}`,
            borderRadius: 6,
            backgroundColor: theme.color.neutralWhite,
            ...theme.typography.body2,
          }}
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          onClick={() => mediaInputRef?.current?.click()}
        >
          <div>
            {state?.mediaState?.name ||
              surgery?.media?.name ||
              t("phr.mediaPlaceholder")}
          </div>
          <img src={uploadIcon} style={{ width: 24, height: 24 }} />
        </Flex>
        <input
          type="file"
          accept="image/*, .pdf"
          ref={mediaInputRef}
          onChange={onMediaChange}
        />
      </Flex>
    </Flex>
  );
}

export default SurgeryForm;
