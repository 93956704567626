import { v4 } from "uuid";
import { IMP_UID, IS_DEBUG } from "../../../utils/constants";
import { useAuthContext } from "../provider/AuthProvider";
import {
  GetMyBillingsDocument,
  useUpdateUserBillingMutation,
} from "../../../lib/apollo/graphql/generated";

function useAddBilling() {
  const { profile } = useAuthContext();

  const [updateUserBilling] = useUpdateUserBillingMutation({
    refetchQueries: () => [
      {
        query: GetMyBillingsDocument,
      },
    ],
  });

  async function onAddClick() {
    const { IMP } = window as any;

    IMP.init(IMP_UID);

    const customerUid = v4();

    const mid = IS_DEBUG ? "iamporttest_4" : "bill_im_biwtb6";

    IMP.request_pay(
      {
        pg: "tosspayments." + mid,
        pay_method: "card",
        merchant_uid: new Date(),
        name: "결제 카드 등록",
        amount: 0,
        buyer_name: profile?.name,
        buyer_email: profile?.email,
        buyer_tel: profile?.phone || "01012345678",
        customer_uid: customerUid,
      },
      async function (response: any) {
        const { success, error_code, error_msg } = response;
        console.log(response);

        if (success || response?.imp_success) {
          updateUserBilling({
            variables: {
              billingInput: {
                customerUid,
              },
            },
          });
        }
      }
    );
  }

  return {
    operations: {
      onAddClick,
    },
  };
}

export default useAddBilling;
