import { useState } from "react";
import { HOME_TAB } from "../../../utils/constants";

function useHomeInstructorList() {
  const [currentTab, setCurrentTab] = useState(HOME_TAB.ONLINE);

  function onTabSelect(tab: string) {
    if (currentTab !== tab) {
      setCurrentTab(tab);
    }
  }

  return {
    models: {
      currentTab,
    },
    operations: {
      onTabSelect,
    },
  };
}

export default useHomeInstructorList;
