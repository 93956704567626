import { useParams } from "react-router-dom";
import { useGetInstructorQuery } from "../../../lib/apollo/graphql/generated";
import { useState } from "react";

function useInstructorContainer() {
  const params = useParams();

  const [showMore, setShowMore] = useState(false);

  const instructorId = params?.instructorId;

  const { loading, data } = useGetInstructorQuery({
    variables: {
      instructorId: instructorId!,
    },
  });

  function toggleShowMore() {
    setShowMore(!showMore);
  }

  return {
    models: {
      loading,
      data: data?.getInstructor,
      showMore,
    },

    operations: {
      toggleShowMore,
    },
  };
}

export default useInstructorContainer;
