import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserPassQuery } from "../../../../lib/apollo/graphql/generated";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import { POLLING, POLLING_TIMEOUT_STATUS } from "../../../../utils/constants";
import Loading from "../../../common/components/Loading";

interface PurchasePollingProps {
  userPassId: string;
}

let getPurchaseSetTimeout: any = null;

function PurchasePolling({ userPassId }: PurchasePollingProps) {
  const navigate = useNavigate();

  const [pollingTimeoutStatus, setPollingTimeoutStatus] = useState(
    POLLING_TIMEOUT_STATUS.NOT_STARTED
  );

  const { startPolling, stopPolling } = useGetUserPassQuery({
    fetchPolicy: "network-only",
    variables: {
      userPassId,
    },
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      console.log(err);
      setPollingTimeoutStatus(POLLING_TIMEOUT_STATUS.ERROR);
    },
    onCompleted: ({ getUserPass }) => {
      if (getUserPass) {
        setPollingTimeoutStatus(POLLING_TIMEOUT_STATUS.FINISHED);
      }
    },
  });

  useEffect(() => {
    setPollingTimeoutStatus(POLLING_TIMEOUT_STATUS.STARTED);
    setGetPurchaseTimeout();
  }, [userPassId]);

  useEffect(() => {
    if (pollingTimeoutStatus === POLLING_TIMEOUT_STATUS.STARTED) {
      startPolling(POLLING.POLLING_INTERVAL);
    }

    if (pollingTimeoutStatus === POLLING_TIMEOUT_STATUS.FINISHED) {
      if (userPassId) {
        stopPolling();
        navigate(SCREENS.PURCHASE + SCREENS.PURCHASE_DONE);
      }
    }
  }, [pollingTimeoutStatus]);

  function setGetPurchaseTimeout() {
    if (getPurchaseSetTimeout) {
      clearTimeout(getPurchaseSetTimeout);
    }
    getPurchaseSetTimeout = setTimeout(() => {
      setPollingTimeoutStatus(POLLING_TIMEOUT_STATUS.FINISHED);
    }, POLLING.TIME_OUT);
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        paddingTop: theme.spacing[40],
      }}
    >
      <Loading />
    </div>
  );
}

export default PurchasePolling;
