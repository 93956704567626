import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import volumeIcon from "../../../assets/icon/Icon_volume.png";
import mutedIcon from "../../../assets/icon/Icon_volumeoff.png";
import theme from "../../../lib/theme";
import Flex from "../../common/components/Flex";
import IconButton from "../../common/components/IconButton";

import Button from "../../common/components/Button";
import { useZoomContext } from "../provider/ZoomProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import ZoomVideo, {
  AudioChangeAction,
  MediaDevice,
  MutedSource,
  Stream,
} from "@zoom/videosdk";
import {
  SessionStatus,
  useGetSessionQuery,
  useLeaveSessionMutation,
  useToggleInterpreterNeededMutation,
  useToggleSessionStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import { ZoomMediaContext } from "../provider/ZoomMediaProvider";
import { useUnmount } from "../../common/utils/useUnmount";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

interface SessionControllerProps {
  sessionId: string;
  joined: boolean;
}

const isAudioEnable = typeof AudioWorklet === "function";

function SessionController({ sessionId, joined }: SessionControllerProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const zoomClient = useZoomContext();
  const zoomMediaContext = useContext(ZoomMediaContext);

  const mediaStream = zoomMediaContext?.mediaStream;

  const [isMuted, setIsMuted] = useState(false);

  const [isStartedAudio, setIsStartedAudio] = useState(
    zoomClient.getCurrentUserInfo() &&
      zoomClient.getCurrentUserInfo().audio !== ""
  );

  const [audio, setAudio] = useState(zoomClient.getCurrentUserInfo()?.audio);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(
    mediaStream?.getSpeakerList() ?? []
  );
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);

  const { data } = useGetSessionQuery({
    variables: {
      sessionId,
    },
  });

  const [leaveSession] = useLeaveSessionMutation();
  const [toggleSessionStatus] = useToggleSessionStatusAsInstructorMutation({
    onCompleted: (data) => {
      if (
        data?.toggleSessionStatusAsInstructor?.status === SessionStatus.Done
      ) {
        navigate(SCREENS.HOME);
      }
    },
  });
  const [toggleInterpreterNeeded] = useToggleInterpreterNeededMutation();

  useEffect(() => {
    // event listener to see when desktop Safari has initialized audio
    zoomClient.on("active-speaker", (payload) => {
      console.log("Active speaker, use for CSS visuals", payload); // new active speaker, for example, use for microphone visuals, css video border, etc.
    });

    zoomClient.on("device-change", () => {
      console.log(mediaStream!.getSpeakerList());
      console.log(mediaStream!.getMicList());

      const speakers = mediaStream!.getSpeakerList();
      const microphones = mediaStream!.getMicList();
    });

    return () => {};
  }, [mediaStream, zoomClient, data?.getSession, joined]);

  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
      } else {
        await mediaStream?.muteAudio();
      }
    } else {
      try {
        await mediaStream?.startAudio();
      } catch (e: any) {
        if (
          e.type === "INSUFFICIENT_PRIVILEGES" &&
          e.reason === "USER_FORBIDDEN_MICROPHONE"
        ) {
          setIsMicrophoneForbidden(true);
        }
        console.warn(e);
      }
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);

  const onHostAudioMuted = useCallback((payload: any) => {
    const { action, source, type } = payload;
    console.log("oh host audio muted");
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
      if (source === MutedSource.PassiveByMuteOne) {
      }
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
    }
  }, []);

  const onLeaveClick = useCallback(async () => {
    await zoomClient.leave();
    toggleSessionStatus({
      variables: {
        sessionId,
        status: SessionStatus.Done,
      },
    });
  }, [zoomClient]);

  const onEndClick = useCallback(async () => {
    await zoomClient.leave(true);
  }, [zoomClient]);

  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setSpeakerList(mediaStream.getSpeakerList());
    }
  }, [mediaStream]);

  const onShareAudioChange = useCallback(
    (payload: any) => {
      const { state } = payload;
      if (state === "on") {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === "off") {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream]
  );

  useEffect(() => {
    zoomClient.on("current-audio-change", onHostAudioMuted);
    zoomClient.on("device-change", onDeviceChange);
    zoomClient.on("share-audio-change", onShareAudioChange);

    return () => {
      zoomClient.off("current-audio-change", onHostAudioMuted);
      zoomClient.off("device-change", onDeviceChange);
      zoomClient.off("share-audio-change", onShareAudioChange);
    };
  }, [zoomClient, onDeviceChange, onShareAudioChange]);

  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
  });

  useEffect(() => {
    if (mediaStream && zoomClient.getSessionInfo().isInMeeting) {
      mediaStream.subscribeAudioStatisticData();
      mediaStream.subscribeVideoStatisticData();
      mediaStream.subscribeShareStatisticData();
    }
    return () => {
      if (zoomClient.getSessionInfo().isInMeeting) {
        mediaStream?.unsubscribeAudioStatisticData();
        mediaStream?.unsubscribeVideoStatisticData();
        mediaStream?.unsubscribeShareStatisticData();
      }
    };
  }, [mediaStream, zoomClient]);

  function onToggleInterpreterNeededClick() {
    if (!data?.getSession) return;

    toggleInterpreterNeeded({
      variables: {
        sessionId,
        needInterpreter: !data?.getSession?.needInterpreter,
      },
    });
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-end"
      padding={`${theme.spacing[16]}px ${theme.spacing[16]}px`}
      border={`1px solid black`}
      borderTop="none"
      gap={theme.spacing[12]}
      backgroundColor={theme.color.neutral200}
    >
      <Flex alignItems="center" gap={theme.spacing[10]}>
        {isStartedAudio ? (
          <IconButton
            size="xl"
            fullWidth
            icon={isMuted ? mutedIcon : volumeIcon}
            onClick={onMicrophoneClick}
          />
        ) : (
          <Button
            text={t("session.turnOnMic")}
            size={isMobile ? "sm" : "lg"}
            round={false}
            onClick={onMicrophoneClick}
          />
        )}
        <Button
          text={
            data?.getSession?.needInterpreter
              ? t("session.seekingInterpreter")
              : t("session.interpreterNeeded")
          }
          size={isMobile ? "sm" : "lg"}
          round={false}
          onClick={onToggleInterpreterNeededClick}
        />
        <Button
          text={t("session.leaveSession")}
          size={isMobile ? "sm" : "lg"}
          round={false}
          variant="outline"
          borderColor="neutral900"
          bgColor="tertiary1"
          fontColor="neutral900"
          onClick={() => onLeaveClick()}
        />
      </Flex>
    </Flex>
  );
}

export default SessionController;
