import { ReactChildrenProp } from "global";
import React from "react";
import ReactModal from "react-modal";
import Flex from "./Flex";
import IconButton from "./IconButton";
import modalCloseIcon from "../../../assets/icon/modal_close.png";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface ModalProps extends ReactModal.Props {
  onClose?: () => void;
}

function Modal({ children, onClose, ...rest }: ModalProps & ReactChildrenProp) {
  const { isMobile } = useScreenWidthContext();

  return (
    <ReactModal
      key="modal"
      {...rest}
      style={{
        overlay: {
          zIndex: 999999999,
          backgroundColor: "rgba(0,0,0,0.4)",
        },

        content: {
          maxWidth: 540,
          margin: "0px auto",
          maxHeight: "90vh",
          height: "fit-content",
          padding: `${theme.spacing[24]}px ${
            isMobile ? theme.spacing[8] : theme.spacing[40] * 2
          }px`,
          inset: isMobile ? 8 : 40,
        },
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        padding={`${theme.spacing[16]}px ${theme.spacing[8]}px`}
        marginBottom={theme.spacing[24]}
      >
        <IconButton
          fullWidth
          variant="text"
          icon={modalCloseIcon}
          size="lg"
          onClick={onClose}
        />
      </Flex>
      {children}
    </ReactModal>
  );
}

export default Modal;
