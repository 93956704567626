import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { slide as Menu } from "react-burger-menu";

import chevronUpIcon from "../../../assets/icon/chevron_up.png";
import theme from "../../../lib/theme";
import Avatar from "../../common/components/Avatar";
import Flex from "../../common/components/Flex";
import IconButton from "../../common/components/IconButton";
import Text from "../../common/components/Text";
import SessionTimer from "./SessionTimer";
import {
  SessionStatus,
  SessionUpdateDocument,
  SessionUpdateSubscription,
  SessionUpdateSubscriptionVariables,
  useGetSessionQuery,
} from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../common/provider/AuthProvider";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import dotIcon from "../../../assets/icon/dot_icon.png";
import modalCloseIcon from "../../../assets/icon/modal_close.png";
import Modal from "../../common/components/Modal";
import SessionUsers from "./SessionUsers";

interface SessionTopBarProps {
  instructor: any;
  extensions?: Array<{
    __typename?: "SessionExtension";
    id: string;
    createdAt: any;
    session: { __typename?: "Session"; id: string };
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  } | null> | null;
  isHistory?: boolean;
}

function SessionTopBar({
  instructor,
  extensions,
  isHistory = false,
}: SessionTopBarProps) {
  const params = useParams();

  const { isMobile } = useScreenWidthContext();

  const sessionId = params?.sessionId;

  const { t } = useTranslation();

  const { profile } = useAuthContext();

  // const [started, setStarted] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  // Session 전달 막기
  // const { data, subscribeToMore } = useGetSessionQuery({
  //   variables: {
  //     sessionId: sessionId!,
  //   },
  // });

  // useEffect(() => {
  //   if (data?.getSession?.status === SessionStatus.Started && !started) {
  //     setStarted(true);
  //   }
  // }, [data?.getSession?.status, started]);

  // useEffect(() => {
  //   const unsubscribe = subscribeToMore<
  //     SessionUpdateSubscription,
  //     SessionUpdateSubscriptionVariables
  //   >({
  //     document: SessionUpdateDocument,
  //     variables: {
  //       sessionId: sessionId!,
  //     },
  //     updateQuery: (prev, { subscriptionData }): any => {
  //       try {
  //         const newSession = subscriptionData?.data?.sessionUpdate;

  //         if (!newSession) return prev;

  //         const prevSession = prev?.getSession;

  //         if (newSession.status === SessionStatus.Started) {
  //           setStarted(true);
  //         } else {
  //           setStarted(false);
  //         }

  //         return {
  //           getSession: newSession,
  //         };
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     },
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  const { avatar, name } = instructor?.user;

  return (
    <>
      {showUserModal && (
        <Modal
          isOpen={showUserModal}
          onClose={() => setShowUserModal(false)}
          onRequestClose={() => setShowUserModal(false)}
        >
          <SessionUsers />
        </Modal>
      )}
      {showMenu && (
        <Menu
          outerContainerId="root"
          right
          isOpen={showMenu}
          styles={{
            bmOverlay: {
              top: "0px",
            },
            bmMenuWrap: {
              top: "0px",
            },
          }}
          width={280}
          onClose={() => setShowMenu(false)}
        >
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            backgroundColor="white"
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              padding={theme.spacing[16]}
            >
              <IconButton
                fullWidth
                variant="text"
                icon={modalCloseIcon}
                size="lg"
                onClick={() => setShowMenu(false)}
              />
            </Flex>
            <div
              style={{
                cursor: "pointer",
                padding: `${theme.spacing[12]}px ${theme.spacing[16]}px`,
                ...theme.typography.body1,
              }}
              onClick={() => {
                setShowMenu(false);
                setShowUserModal(true);
              }}
            >
              {isHistory
                ? t("session.showUsersAndReport")
                : t("session.showUsers")}
            </div>
          </Flex>
        </Menu>
      )}
      {isMobile && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.spacing[12]}
          position="relative"
        >
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            gap={theme.spacing[4]}
          >
            <Avatar
              uri={avatar?.uri}
              initial={name?.slice(0, 1)}
              size={isMobile ? 32 : 40}
              showOnline={false}
            />
            <Text typography={isMobile ? "body2Bold" : "body1Bold"}>
              {name}
            </Text>
          </Flex>
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 4,
            }}
            onClick={() => setShowMenu(true)}
          >
            <img src={dotIcon} style={{ width: 32, height: 32 }} />
          </div>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        padding={`${theme.spacing[8]}px ${theme.spacing[16]}px`}
        border={`1px solid black`}
        gap={theme.spacing[12]}
        backgroundColor={theme.color.neutral200}
      >
        <Flex alignItems="center" gap={theme.spacing[8]} position="relative">
          {!isMobile && (
            <Avatar
              uri={avatar?.uri}
              initial={name?.slice(0, 1)}
              size={isMobile ? 32 : 40}
              showOnline={false}
            />
          )}
          <Text typography={isMobile ? "body2Bold" : "body1Bold"}>
            {name}
            {t(
              isHistory ? "session.historyTopBarTitle" : "session.topBarTitle"
            )}
          </Text>
        </Flex>
        {!isHistory && (
          <Flex alignItems="center" gap={theme.spacing[10]}>
            {/* <SessionTimer
              started={started}
              startedAt={data?.getSession?.startAt}
              extensions={extensions}
            /> */}
            {!!profile?.isSubscriber && (
              <div
                style={{
                  padding: `${theme.spacing[4]}px ${theme.spacing[8]}px`,
                  backgroundColor: theme.color.secondary3,
                  borderRadius: 12,
                  border: `1px solid ${theme.color.primary1}`,
                  ...theme.typography.body2,
                }}
              >
                {t("session.sessionSubscriberLeft")} {profile?.usuableTickets}{" "}
                {t("common.ticketCount")}
              </div>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default SessionTopBar;
