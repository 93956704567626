import React from "react";
import Section from "../../../common/components/Section";
import theme from "../../../../lib/theme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import MyPageHeader from "../../common/components/MyPageHeader";
import Divider from "../../../common/components/Divider";
import Spacer from "../../../common/components/Spacer";
import useMySessionContainer from "./MySessionContainer.hook";
import Loading from "../../../common/components/Loading";
import Text from "../../../common/components/Text";
import Button from "../../../common/components/Button";
import { SCREENS } from "../../../../lib/route/utils/router";
import Flex from "../../../common/components/Flex";
import MySessionCard from "../components/MySessionCard";
import FetchMore from "../../../common/components/FetchMore";

function MySessionContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    refs: { ref },
    models: { loading, data },
  } = useMySessionContainer();

  return (
    <Section
      innerStyle={{
        width: "100%",
        maxWidth: 794,
        paddingTop: theme.spacing[isMobile ? 16 : 48],
        paddingBottom: theme.spacing[isMobile ? 16 : 48],
      }}
    >
      <MyPageHeader title={t("myPage.sessionTitle")} />
      <Divider full size={1} />
      <Spacer size={24} />

      {loading ? (
        <Loading />
      ) : !!data && data?.length > 0 ? (
        <Flex width="100%" gap={theme.spacing[24]} flexDirection="column">
          {data?.map((session) => (
            <MySessionCard key={session?.id} session={session!} />
          ))}
          <FetchMore fetchMoreRef={ref} />
        </Flex>
      ) : (
        <>
          <Text typography="heading4" marginBottom={theme.spacing[24]}>
            {t("myPage.sessionNone")}
          </Text>
          <Button
            size="lg"
            text={t("myPage.goToRequestSession")}
            onClick={() => navigate(SCREENS.HOME)}
          />
        </>
      )}
    </Section>
  );
}

export default MySessionContainer;
