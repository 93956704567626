import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import useInsuranceForm from "./InsuranceForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";

export interface InsuranceFormProps {
  insurance: {
    __typename?: "UserInsurance";
    id: string;
    type?: string | null;
    name?: string | null;
    coverage?: string | null;
    memberId?: string | null;
    groupId?: string | null;
    companyNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null;
}

function InsuranceForm({ insurance }: InsuranceFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    refs: { mediaInputRef },
    models: { state },
    operations: { onInputChange, onDeleteClick, onMediaChange, onAddClick },
  } = useInsuranceForm({ insurance });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      backgroundColor={
        !insurance ? theme.color.neutralWhite : theme.color.secondary3
      }
      padding={theme.spacing[8]}
    >
      {!insurance && (
        <PHRAddButton text="insuranceTitle" onClick={onAddClick} />
      )}
      {!!insurance && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <Input
        label={t("phr.insuranceType")}
        value={state?.type}
        placeholder={t("phr.insuranceTypePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "type")
        }
      />

      <Input
        label={t("phr.name")}
        value={state?.name}
        placeholder={t("phr.namePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "name")
        }
      />
      <Input
        label={t("phr.coverage")}
        value={state?.coverage}
        placeholder={t("phr.coveragePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "coverage")
        }
      />
      <Input
        label={t("phr.memberId")}
        value={state?.memberId}
        placeholder={t("phr.memberIdPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "memberId")
        }
      />
      <Input
        label={t("phr.groupId")}
        value={state?.groupId}
        placeholder={t("phr.groupIdPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "groupId")
        }
      />
      <Input
        label={t("phr.companyNumber")}
        value={state?.companyNumber}
        placeholder={t("phr.companyNumberPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "companyNumber")
        }
      />
    </Flex>
  );
}

export default InsuranceForm;
