import { useGetMyInsurancesQuery } from "../../../../lib/apollo/graphql/generated";

function useInsuranceContainer() {
  const { loading, data } = useGetMyInsurancesQuery();

  return {
    models: {
      loading,
      data: data?.getMyInsurances,
    },
  };
}

export default useInsuranceContainer;
