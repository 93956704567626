import React from "react";
import { useTranslation } from "react-i18next";
import useAddBilling from "./AddBilling.hook";
import Flex from "./Flex";
import Text from "./Text";
import addIcon from "../../../assets/icon/circle_add.png";
import theme from "../../../lib/theme";

function AddBilling() {
  const { t } = useTranslation();

  const {
    operations: { onAddClick },
  } = useAddBilling();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      border={`1px solid ${theme.color.neutral800}`}
      borderRadius={8}
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
      cursor="pointer"
      onClick={onAddClick}
    >
      <Text typography="heading3" color={theme.color.neutral700}>
        {t("purchase.addCard")}
      </Text>
      <Text
        typography="heading4"
        color={theme.color.neutral500}
        marginBottom={theme.spacing[40]}
      >
        {t("purchase.addCardDescription")}
      </Text>
      <img
        src={addIcon}
        style={{ width: 24, height: 24, marginBottom: theme.spacing[16] }}
      />
    </Flex>
  );
}

export default AddBilling;
