import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import closeIcon from "../../../assets/icon/modal_close.png";
import IconButton from "../../common/components/IconButton";
import Text from "../../common/components/Text";
import { useTranslation } from "react-i18next";
import Textarea from "../../common/components/Textarea";
import Button from "../../common/components/Button";

interface SessionReportProps {
  report?: string | null;
  onClose?: () => void;
}

function SessionReport({ report: _report, onClose }: SessionReportProps) {
  const params = useParams();

  const sessionId = params?.sessionId!;

  const { t } = useTranslation();

  const [report, setReport] = useState(_report || "");

  return (
    <Flex
      backgroundColor={theme.color.neutral200}
      padding={`${theme.spacing[16]}px ${theme.spacing[8]}px`}
      border={`1px solid black`}
      height="100%"
      width="100%"
      flex={1}
      flexDirection="column"
      alignItems="center"
    >
      <Text typography="body1Bold" marginBottom={theme.spacing[8]}>
        {t("session.reportTitle")}
      </Text>
      <Textarea
        value={report}
        placeholder={t("session.reportPlaceholder")}
        style={{
          height: "40vh",
        }}
        disabled
      />
    </Flex>
  );
}

export default SessionReport;
