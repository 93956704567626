import { useGetMySubscriptionsQuery } from "../../../../lib/apollo/graphql/generated";

function useMySubscriptionContainer() {
  const { loading, data } = useGetMySubscriptionsQuery();

  return {
    models: {
      loading,
      data: data?.getMySubscriptions,
    },
  };
}

export default useMySubscriptionContainer;
