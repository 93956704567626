import React from "react";
import { useTranslation } from "react-i18next";
import usePurchaseContainer from "./PurchaseContainer.hook";
import checkedIcon from "../../../../assets/icon/checkbox_checked.png";
import uncheckedIcon from "../../../../assets/icon/checkbox_unchecked.png";
import theme from "../../../../lib/theme";
import AddBilling from "../../../common/components/AddBilling";
import BillingCard from "../../../common/components/BillingCard";
import Button from "../../../common/components/Button";
import Divider from "../../../common/components/Divider";
import Flex from "../../../common/components/Flex";
import Layout from "../../../common/components/Layout";
import Loading from "../../../common/components/Loading";
import Section from "../../../common/components/Section";
import Text from "../../../common/components/Text";
import { useAuthContext } from "../../../common/provider/AuthProvider";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import { getNumbersWithCommas } from "../../../common/utils/getNumbersWithCommas";
import PurchaseItemCard from "../../common/components/PurchaseItemCard";
import PurchasePolling from "../components/PurchasePolling";

function PurchaseContainer() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const { profile } = useAuthContext();

  const {
    models: {
      loading,
      passData,
      planData,
      isSubscription,
      billings,
      selectedPaymentMethod,
      polling,
      updating,
    },
    operations: { onMethodClick, onSubmit },
  } = usePurchaseContainer();

  if (loading) return <Loading />;

  return (
    <Layout>
      {polling && <PurchasePolling userPassId={polling} />}
      <Section innerStyle={{ width: "100%", maxWidth: 670 }}>
        <Text
          typography={isMobile ? "heading3" : "heading2"}
          color={theme.color.neutral800}
          marginBottom={theme.spacing[32]}
        >
          {t("purchase.purchaseTitle")}
        </Text>
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          gap={theme.spacing[24]}
        >
          <PurchaseItemCard
            name={
              isSubscription
                ? planData?.getSubscriptionPlan?.name!
                : passData?.getPass?.name!
            }
            price={
              isSubscription
                ? planData?.getSubscriptionPlan?.finalPrice!
                : passData?.getPass?.finalPrice!
            }
            summary={
              isSubscription
                ? planData?.getSubscriptionPlan?.summary
                : passData?.getPass?.summary
            }
            description={
              isSubscription
                ? planData?.getSubscriptionPlan?.description
                : passData?.getPass?.description
            }
            onList={false}
          />
          <Divider size={4} full />
          <Flex flexDirection="column" gap={theme.spacing[16]} width="100%">
            <Text typography="heading3" color={theme.color.neutral800}>
              {t("purchase.purchaserTitle")}
            </Text>
            <Flex flexDirection="column" gap={theme.spacing[8]} width="100%">
              <Text typography="body1Bold" color={theme.color.neutral500}>
                {t("common.name")}
              </Text>
              <Text typography="body2" color={theme.color.neutral800}>
                {profile?.name}
              </Text>
            </Flex>
            <Flex flexDirection="column" gap={theme.spacing[8]} width="100%">
              <Text typography="body1Bold" color={theme.color.neutral500}>
                {t("common.email")}
              </Text>
              <Text typography="body2" color={theme.color.neutral800}>
                {profile?.email}
              </Text>
            </Flex>
          </Flex>
          <Divider size={4} full />
          <Flex flexDirection="column" gap={theme.spacing[24]} width="100%">
            <Text typography="heading3" color={theme.color.neutral800}>
              {t("purchase.purchaseMethod")}
            </Text>
            {!isSubscription && (
              <Flex
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Text typography="heading3" color={theme.color.neutral800}>
                  {t("purchase.oneTimeMethod")}
                </Text>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    onMethodClick({ billingId: "onetime", customerUid: null })
                  }
                >
                  <img
                    src={
                      selectedPaymentMethod?.billingId === "onetime"
                        ? checkedIcon
                        : uncheckedIcon
                    }
                    style={{ width: 20, height: 20 }}
                  />
                </div>
              </Flex>
            )}

            <Flex flexDirection="column" width="100%" gap={theme.spacing[16]}>
              {billings?.map((billing) => (
                <BillingCard
                  key={billing?.id}
                  cardName={billing?.cardName}
                  cardNumber={billing?.cardNumber}
                  isActive={selectedPaymentMethod?.billingId === billing?.id}
                  onClick={() =>
                    onMethodClick({
                      billingId: billing?.id,
                      customerUid: billing?.customerUid,
                    })
                  }
                />
              ))}
              <AddBilling />
            </Flex>
          </Flex>

          <Divider size={4} full />
          <Flex alignItems="center" width="100%" justifyContent="space-between">
            <Text typography="body1Bold" color={theme.color.neutral500}>
              {t("purchase.total")}
            </Text>
            <Text typography="body1Bold" color={theme.color.neutral500}>
              {t("common.currencyUnit")}
              {getNumbersWithCommas(
                isSubscription
                  ? planData?.getSubscriptionPlan?.finalPrice || 0
                  : passData?.getPass?.finalPrice || 0
              )}
            </Text>
          </Flex>
          <Divider size={4} full />

          <Text
            width="100%"
            textAlign="center"
            typography="body2"
            color={theme.color.neutral800}
          >
            {t("purchase.agreement")}
          </Text>

          <Button
            text={t("common.purchase")}
            round={false}
            size="lg"
            disabled={!selectedPaymentMethod || updating}
            onClick={onSubmit}
          />
        </Flex>
      </Section>
    </Layout>
  );
}

export default PurchaseContainer;
