import React from "react";
import { useTranslation } from "react-i18next";
import usePurchaseItemsContainer from "./PurchaseItemsContainer.hook";
import theme from "../../../../lib/theme";
import { PURCHASE_TYPE } from "../../../../utils/constants";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Layout from "../../../common/components/Layout";
import Loading from "../../../common/components/Loading";
import Section from "../../../common/components/Section";
import Text from "../../../common/components/Text";
import { useAuthContext } from "../../../common/provider/AuthProvider";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import PurchaseItemCard from "../../common/components/PurchaseItemCard";

function PurchaseItemsContainer() {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const { profile } = useAuthContext();

  const {
    models: { loading, data, selectedItem },
    operations: { onItemSelect, onSubmit },
  } = usePurchaseItemsContainer();

  if (loading) return <Loading />;

  return (
    <Layout>
      <Section>
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Text
            textAlign="center"
            typography={isMobile ? "heading3" : "heading2"}
            color={theme.color.neutral700}
            marginBottom={theme.spacing[isMobile ? 16 : 24]}
          >
            {t("purchase.itemsTitle")}
          </Text>

          <Text
            textAlign="center"
            typography={isMobile ? "heading5" : "heading3"}
            color={theme.color.neutral700}
            marginBottom={theme.spacing[isMobile ? 12 : 40]}
          >
            {t("purchase.itemsSummary")}
          </Text>

          <Flex
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
            width="100%"
            justifyContent="center"
            gap={theme.spacing[isMobile ? 12 : 20]}
            marginBottom={theme.spacing[isMobile ? 16 : 40]}
          >
            {!profile?.isSubscriber &&
              data?.getSubscriptionPlansByLanguage?.map((plan) => (
                <PurchaseItemCard
                  key={plan?.id}
                  name={plan?.name!}
                  price={plan?.finalPrice!}
                  summary={plan?.summary}
                  description={plan?.description}
                  isSelected={
                    selectedItem?.type === PURCHASE_TYPE.SUBSCRIPTION &&
                    selectedItem?.id === plan?.id
                  }
                  onClick={() =>
                    onItemSelect(PURCHASE_TYPE.SUBSCRIPTION, plan?.id!)
                  }
                />
              ))}
            {data?.getPassesByLanguage?.map((pass) => (
              <PurchaseItemCard
                key={pass?.id}
                name={pass?.name!}
                price={pass?.finalPrice!}
                summary={pass?.summary}
                description={pass?.description}
                isSelected={
                  selectedItem?.type === PURCHASE_TYPE.PASS &&
                  selectedItem?.id === pass?.id
                }
                onClick={() => onItemSelect(PURCHASE_TYPE.PASS, pass?.id!)}
              />
            ))}
          </Flex>

          <Button
            text={t("common.purchase")}
            size="lg"
            round={false}
            disabled={!selectedItem?.type && !selectedItem?.id}
            onClick={onSubmit}
          />
        </Flex>
      </Section>
    </Layout>
  );
}

export default PurchaseItemsContainer;
