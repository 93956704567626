import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { SCREENS } from "../../../../lib/route/utils/router";
import { useAuthContext } from "../../../common/provider/AuthProvider";
import {
  GetMySubscriptionsDocument,
  GetMyUserProfileDocument,
  useAddSubscriptionMutation,
  useGetMyBillingsQuery,
  useGetPassLazyQuery,
  useGetSubscriptionPlanLazyQuery,
  useGetUserPassLazyQuery,
  usePurchaseUserPassByBillingMutation,
} from "../../../../lib/apollo/graphql/generated";
import {
  IMP_UID,
  IS_DEBUG,
  POLLING_TIMEOUT_STATUS,
  PURCHASE_TYPE,
} from "../../../../utils/constants";

function usePurchaseContainer() {
  const navigate = useNavigate();
  const params = useParams();

  const { profile } = useAuthContext();

  const { planId, passId } = params;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    billingId: string | null;
    customerUid: string | null;
  }>({ billingId: null, customerUid: null });

  const [polling, setPolling] = useState<string | null>(null);

  const { loading, data } = useGetMyBillingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [getPass, { loading: passLoading, data: passData }] =
    useGetPassLazyQuery();

  const [getSubscriptionPlan, { loading: planLoading, data: planData }] =
    useGetSubscriptionPlanLazyQuery();

  const [addSubscription, { loading: updating }] = useAddSubscriptionMutation({
    onCompleted: (data) => {
      console.log(data?.addSubscription);
      if (data?.addSubscription) {
        navigate(SCREENS.PURCHASE + SCREENS.PURCHASE_DONE);
      }
    },
    refetchQueries: () => [
      {
        query: GetMySubscriptionsDocument,
      },
      {
        query: GetMyUserProfileDocument,
      },
    ],
  });

  const [purchaseUserPassByBilling, { loading: updateUserPassLoading }] =
    usePurchaseUserPassByBillingMutation({
      onCompleted: (data) => {
        if (data?.purchaseUserPassByBilling) {
          navigate(SCREENS.PURCHASE + SCREENS.PURCHASE_DONE);
        } else {
          setPolling(null);
        }
      },
      onError: (err) => {
        setPolling(null);
      },
    });

  useEffect(() => {
    if (planId) {
      getSubscriptionPlan({
        variables: {
          planId,
        },
      });
    }

    if (passId) {
      getPass({
        variables: {
          passId,
        },
      });
    }
  }, [planId, passId]);

  function onMethodClick(method: any) {
    setSelectedPaymentMethod(method);
  }

  function onSubmit() {
    if (selectedPaymentMethod?.billingId === "onetime") {
      const { IMP } = window as any;

      IMP.init(IMP_UID);

      const mid = IS_DEBUG ? "iamporttest_3" : "im_biemesamzx";

      const userPassId = v4();

      const customData = {
        userPassId,
        userPurchaseId: v4(),
        userId: profile?.id,
        passId,
        billingId: null,
        purchaseType: PURCHASE_TYPE.PASS,
      };

      const params = {
        // param
        pg: "tosspayments",
        pay_method: "card",
        merchant_uid: "order_id_" + v4(),
        name: passData?.getPass?.name,
        amount: passData?.getPass?.finalPrice,
        buyer_email: profile?.email,
        buyer_name: profile?.name,
        buyer_tel: profile?.phone || "01012345678",
        custom_data: customData,
        orderId: userPassId,
      };

      IMP.request_pay(params, (response: any) => {
        if (response?.merchant_uid && !response?.error_code) {
          setPolling(userPassId);
        } else {
          console.log(response?.error_msg);
        }
      });
    } else {
      if (planId) {
        addSubscription({
          variables: {
            subscriptionPlanId: planId,
            billingId: selectedPaymentMethod?.billingId!,
            finalPrice: planData?.getSubscriptionPlan?.finalPrice!,
          },
        });
      } else {
        const userPassId = v4();

        setPolling(userPassId);

        purchaseUserPassByBilling({
          variables: {
            passId: passId!,
            billingId: selectedPaymentMethod?.billingId!,
            userPassId,
          },
        });
      }
    }
  }

  return {
    models: {
      loading: planLoading || passLoading,
      passData,
      planData,
      billings: data?.getMyBillings,
      isSubscription: !!planId,
      selectedPaymentMethod,
      polling,
      updating: updating || updateUserPassLoading,
    },
    operations: {
      onMethodClick,
      onSubmit,
    },
  };
}

export default usePurchaseContainer;
