import React from "react";
import { Trans, useTranslation } from "react-i18next";
import homeImage from "../../../assets/image/home_image.png";
import theme from "../../../lib/theme";
import Flex from "../../common/components/Flex";
import Section from "../../common/components/Section";
import Text from "../../common/components/Text";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

function HomeTopSection() {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  return (
    <Section
      wrapperStyle={{
        borderTop: `1px solid ${theme.color.neutral800}`,
        borderBottom: `1px solid ${theme.color.neutral800}`,
        backgroundColor: theme.color.secondary3,
      }}
    >
      <Flex
        width="100%"
        flexDirection={isMobile ? "column-reverse" : "row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex flex={1} flexDirection="column" alignItems="flex-start">
          <Text typography="heading3" color={theme.color.neutral900}>
            <Trans
              i18nKey="home.mainTitle"
              components={{
                1: <span style={{ color: theme.color.primary2 }} />,
              }}
            />
          </Text>
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          marginBottom={isMobile ? theme.spacing[8] : 0}
        >
          <img
            src={homeImage}
            style={{
              width: isMobile ? 187 : 375,
              height: isMobile ? 125 : 251,
              objectFit: "contain",
            }}
          />
        </Flex>
      </Flex>
    </Section>
  );
}

export default HomeTopSection;
