import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Accordion from "./Accordion";
import useAdditionalProfileForm from "./AdditionalProfileForm.hook";
import BloodTypeInput from "./BloodTypeInput";
import Button from "./Button";
import Flex from "./Flex";
import Input from "./Input";
import Loading from "./Loading";
import NationalityInput from "./NationalityInput";
import PhoneInput from "./PhoneInput";
import { BloodType } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface ExperienceProfileFormProps {
  isCreating?: boolean;
}

function ExperienceProfileForm({
  isCreating = false,
}: ExperienceProfileFormProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const {
    models: { state, loading, updateLoading },
    operations: {
      onInputChange,
      onNationalityChange,
      onPhoneCodeChange,
      onBloodTypeChange,
      onSubmit,
    },
  } = useAdditionalProfileForm(isCreating);

  const {
    weight,
    height,
    bloodType,
    occupation,
    companyName,
    emergencyCallerName,
    emergencyCallerNationality,
    emergencyCallerPhoneCode,
    emergencyCallerPhone,
    emergencyCallerBloodType,
    emergencyCallerRelation,
    emergencyCallerAddress,
  } = state;

  const memoizedWeightInput = useMemo(
    () => (
      <Input
        type="number"
        inputMode="decimal"
        label={t("common.weight")}
        placeholder={t("common.weightPlaceholder")}
        value={weight}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "weight")
        }
      />
    ),
    [weight]
  );

  const memoizedHeightInput = useMemo(
    () => (
      <Input
        type="number"
        inputMode="decimal"
        label={t("common.height")}
        placeholder={t("common.heightPlaceholder")}
        value={height}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "height")
        }
      />
    ),
    [height]
  );

  const memoizedBloodTypeInput = useMemo(
    () => (
      <BloodTypeInput
        label={t("common.bloodType")}
        selectedBloodType={bloodType}
        onChange={(type: BloodType) => onBloodTypeChange(type, "bloodType")}
      />
    ),
    [bloodType]
  );

  const memoizedOccupationInput = useMemo(
    () => (
      <Input
        label={t("common.occupation")}
        placeholder={t("common.occupationPlaceholder")}
        value={occupation}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "occupation")
        }
      />
    ),
    [occupation]
  );

  const memoizedCompanyNameInput = useMemo(
    () => (
      <Input
        label={t("common.companyName")}
        placeholder={t("common.companyNamePlaceholder")}
        value={companyName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "companyName")
        }
      />
    ),
    [companyName]
  );

  const memoizedEmergencyCallerNameInput = useMemo(
    () => (
      <Input
        label={t("common.name")}
        placeholder={t("common.namePlaceholder")}
        value={emergencyCallerName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "emergencyCallerName")
        }
      />
    ),
    [emergencyCallerName]
  );

  const memoizedEmergencyCallerRelationInput = useMemo(
    () => (
      <Input
        label={t("common.relation")}
        placeholder={t("common.relationPlaceholder")}
        value={emergencyCallerRelation}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "emergencyCallerRelation")
        }
      />
    ),
    [emergencyCallerRelation]
  );

  const memoizedEmergencyCallerNationalityInput = useMemo(
    () => (
      <NationalityInput
        label={t("common.nationality")}
        value={emergencyCallerNationality}
        onChange={onNationalityChange}
      />
    ),
    [emergencyCallerNationality]
  );

  const memoizedEmergencyCallerBloodTypeInput = useMemo(
    () => (
      <BloodTypeInput
        label={t("common.bloodType")}
        selectedBloodType={emergencyCallerBloodType}
        onChange={(type: BloodType) =>
          onBloodTypeChange(type, "emergencyCallerBloodType")
        }
      />
    ),
    [emergencyCallerBloodType]
  );

  const memoizedEmergencyCallerPhoneInput = useMemo(
    () => (
      <PhoneInput
        phoneCode={emergencyCallerPhoneCode}
        label={t("common.phone")}
        inputMode="tel"
        placeholder={t("common.phonePlaceholder")}
        value={emergencyCallerPhone}
        maxLength={11}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "emergencyCallerPhone")
        }
        onPhoneCodeChange={onPhoneCodeChange}
      />
    ),
    [emergencyCallerPhone, emergencyCallerPhoneCode]
  );

  const memoizedEmergencyCallerAddressInput = useMemo(
    () => (
      <Input
        label={t("common.address")}
        placeholder={t("common.addressPlaceholder")}
        value={emergencyCallerAddress}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "emergencyCallerAddress")
        }
      />
    ),
    [emergencyCallerAddress]
  );

  if (loading) return <Loading />;

  return (
    <>
      {/* PERSONAL  */}
      <Accordion title={t("common.profile")} defaultState={true}>
        <Flex width="100%" alignItems="center" flexDirection="column">
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="flex-start"
            padding={theme.spacing[8]}
            gap={theme.spacing[24]}
            marginBottom={theme.spacing[24]}
            backgroundColor={"white"}
          >
            {memoizedWeightInput}
            {memoizedHeightInput}
            {memoizedBloodTypeInput}
            {memoizedOccupationInput}
            {memoizedCompanyNameInput}
          </Flex>
        </Flex>
      </Accordion>

      {/* EMERGENCY CALLER  */}
      <Accordion title={t("common.emergencyCaller")} defaultState={true}>
        <Flex width="100%" alignItems="center" flexDirection="column">
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="flex-start"
            padding={theme.spacing[8]}
            gap={theme.spacing[24]}
            marginBottom={theme.spacing[24]}
            backgroundColor={"white"}
          >
            {memoizedEmergencyCallerNameInput}
            {memoizedEmergencyCallerRelationInput}
            {memoizedEmergencyCallerNationalityInput}
            {memoizedEmergencyCallerBloodTypeInput}
            {memoizedEmergencyCallerPhoneInput}
            {memoizedEmergencyCallerAddressInput}
          </Flex>
        </Flex>
      </Accordion>

      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop={isMobile ? 32 : isCreating ? 48 : 24}
        gap={theme.spacing[24]}
      >
        {isCreating && (
          <Button
            variant="outline"
            text={t("common.prev")}
            fontColor="neutral700"
            size={isMobile ? "md" : "lg"}
            onClick={() => navigate(-1)}
          />
        )}
        <Button
          disabled={updateLoading}
          text={
            updateLoading
              ? t("common.saving")
              : isCreating
              ? t("common.next")
              : t("common.save")
          }
          size={isMobile ? "md" : "lg"}
          onClick={onSubmit}
        />
      </Flex>
    </>
  );
}

export default ExperienceProfileForm;
