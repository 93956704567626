import React from "react";
import Layout from "../../../common/components/Layout";
import Section from "../../../common/components/Section";
import PHRNavigationBar from "../component/PHRNavigationBar";
import theme from "../../../../lib/theme";
import PHRMainTitle from "../component/PHRMainTitle";
import Flex from "../../../common/components/Flex";
import usePHRContainer from "./PHRContainer.hook";
import { Outlet } from "react-router-dom";

function PHRContainer() {
  usePHRContainer();

  return (
    <Layout>
      <Section>
        <PHRNavigationBar />
        <Section
          innerStyle={{
            padding: `${theme.spacing[32]}px ${theme.spacing[8]}px`,
            maxWidth: 884,
            width: "100%",
          }}
        >
          <Flex flexDirection="column" width="100%" gap={theme.spacing[24]}>
            <PHRMainTitle />
            <Outlet />
          </Flex>
        </Section>
      </Section>
    </Layout>
  );
}

export default PHRContainer;
