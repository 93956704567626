import React from "react";
import Flex from "../../../common/components/Flex";
import { useLocation, useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";
import Grid from "../../../common/components/Grid";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import Label from "../../../common/components/Label";

function PHRNavigationBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isMobile } = useScreenWidthContext();

  const menus = [
    {
      title: "Chronic disease",
      isCurrent: pathname?.includes(SCREENS.CHRONIC_DISEASE),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.CHRONIC_DISEASE);
      },
    },
    {
      title: "Allergies",
      isCurrent: pathname?.includes(SCREENS.ALLERGIES),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.ALLERGIES);
      },
    },
    {
      title: "Medicine",
      isCurrent: pathname?.includes(SCREENS.MEDICINE),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.MEDICINE);
      },
    },
    {
      title: "Vaccination",
      isCurrent: pathname?.includes(SCREENS.VACCINATION),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.VACCINATION);
      },
    },
    {
      title: "Surgery",
      isCurrent: pathname?.includes(SCREENS.SURGERY),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.SURGERY);
      },
    },
    {
      title: "Insurance",
      isCurrent: pathname?.includes(SCREENS.INSURANCE),
      onClick: () => {
        navigate(SCREENS.PHR + SCREENS.INSURANCE);
      },
    },
  ];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      backgroundColor={theme.color.secondary3}
      borderRadius={isMobile ? 0 : 40}
      borderBottom={isMobile ? `1px solid black` : "none"}
    >
      <Grid
        width="100%"
        maxWidth={900}
        alignItems="center"
        gridTemplateColumns={
          isMobile
            ? `repeat(${menus?.length / 2}, 1fr)`
            : `repeat(${menus?.length}, 1fr)`
        }
        gridAutoRows="auto"
      >
        {menus?.map((menu) => (
          <Label
            key={menu?.title}
            text={menu?.title}
            current={menu?.isCurrent}
            size="lg"
            onClick={menu?.onClick}
          />
        ))}
      </Grid>
    </Flex>
  );
}

export default PHRNavigationBar;
