import { useTranslation } from "react-i18next";
import {
  GetMyVaccinesDocument,
  useDeleteMyVaccineMutation,
  useGetVaccineTypesQuery,
  useUpdateMyVaccineMutation,
} from "../../../../lib/apollo/graphql/generated";
import { VaccineFormProps } from "./VaccineForm";
import { useState } from "react";
import dayjs from "dayjs";

function useVaccineForm({ vaccine }: VaccineFormProps) {
  const { i18n } = useTranslation();

  const [state, setState] = useState({
    id: vaccine?.id || null,
    typeId: vaccine?.vaccineType?.id || null,
    level: vaccine?.level || "",
    lastVaccinatedAt: vaccine?.lastVaccinatedAt || dayjs().format("YYYY-MM-DD"),
  });

  const { loading, data } = useGetVaccineTypesQuery({
    variables: {
      language: i18n.resolvedLanguage || "ko",
      activeOnly: true,
    },
  });

  const [updateMyVaccine] = useUpdateMyVaccineMutation({
    onCompleted: () => {
      setState({
        id: vaccine?.id || null,
        typeId: vaccine?.vaccineType?.id || null,
        level: vaccine?.level || "",
        lastVaccinatedAt:
          vaccine?.lastVaccinatedAt || dayjs().format("YYYY-MM-DD"),
      });
    },
    refetchQueries: !vaccine
      ? () => [
          {
            query: GetMyVaccinesDocument,
          },
        ]
      : undefined,
  });
  const [deleteMyVaccine] = useDeleteMyVaccineMutation({
    refetchQueries: () => [
      {
        query: GetMyVaccinesDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onTypeChange(id: string) {
    setState((prev) => ({
      ...prev,
      typeId: id,
    }));
  }

  function onDeleteClick() {
    deleteMyVaccine({
      variables: {
        userVaccineId: vaccine?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { typeId, level, lastVaccinatedAt } = state;

    return !typeId || !lastVaccinatedAt;
  }

  function onAddClick() {
    if (isSubmitDisabled()) return;

    const { typeId, level, lastVaccinatedAt } = state;

    updateMyVaccine({
      variables: {
        userVaccineInput: {
          id: vaccine?.id,
          vaccineTypeId: typeId!,
          level,
          lastVaccinatedAt,
        },
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getVaccineTypes,
      state,
    },
    operations: {
      onInputChange,
      onTypeChange,
      onDeleteClick,
      onAddClick,
    },
  };
}

export default useVaccineForm;
