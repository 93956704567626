import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";

function usePHRContainer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === SCREENS.PHR) {
      navigate(SCREENS.PHR + SCREENS.CHRONIC_DISEASE, { replace: true });
    }
  }, [pathname]);
}

export default usePHRContainer;
