import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Flex from "./Flex";
import Loading from "./Loading";
import Text from "./Text";
import { SCREENS } from "../../../lib/route/utils/router";
import theme from "../../../lib/theme";
import {
  GetMyProfileDocument,
  GetMyUserProfileDocument,
  InvitationStatus,
  InvitationUpdatesDocument,
  InvitationUpdatesSubscription,
  InvitationUpdatesSubscriptionVariables,
  SubscriptionStatus,
  UserPassStatus,
  useCancelMyPendingInvitationMutation,
  useGetInvitationLazyQuery,
  useGetMyOwnUserPassesQuery,
  useGetMySubscriptionsQuery,
  useGetMyUserProfileQuery,
  useSendInvitationMutation,
} from "../../../lib/apollo/graphql/generated";
import { useAuthContext } from "../provider/AuthProvider";

interface InvitationModalProps {
  instructorId: string;
  online?: boolean;
  onClose?: () => void;
}

function InvitationModal({
  instructorId,
  online = false,
  onClose,
}: InvitationModalProps) {
  const navigate = useNavigate();
  const { profile } = useAuthContext();

  const { t } = useTranslation();

  const [invitationId, setInvitationId] = useState<string | null>(null);

  const { loading, data } = useGetMyUserProfileQuery();
  const { loading: subscriptionLoading, data: subscription } =
    useGetMySubscriptionsQuery();

  const { loading: userPassLoading, data: userPass } =
    useGetMyOwnUserPassesQuery({
      variables: {
        first: 100,
        status: [UserPassStatus.Purchased, UserPassStatus.Received],
      },
    });

  const [
    getInvitation,
    { loading: invitationLoading, data: invitationData, subscribeToMore },
  ] = useGetInvitationLazyQuery();

  const [sendInvitation] = useSendInvitationMutation({
    onCompleted: (data) => {
      if (data?.sendInvitation) {
        setInvitationId(data?.sendInvitation?.id);
        getInvitation({
          variables: {
            invitationId: data?.sendInvitation?.id,
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: GetMyUserProfileDocument,
      },
    ],
  });

  const [cancelMyPendingInvitation, { loading: cancelLoading }] =
    useCancelMyPendingInvitationMutation({
      onCompleted: (data) => {
        if (
          data?.cancelMyPendingInvitation?.status === InvitationStatus.Cancelled
        ) {
          onClose && onClose();
        }
      },
      onError: () => {
        onClose && onClose();
      },
    });

  useEffect(() => {
    if (invitationId) {
      const unsubscribe = subscribeToMore<
        InvitationUpdatesSubscription,
        InvitationUpdatesSubscriptionVariables
      >({
        document: InvitationUpdatesDocument,
        updateQuery: (prev, { subscriptionData }): any => {
          try {
            const newInvitation = subscriptionData?.data?.invitationUpdates;

            if (!newInvitation) return prev;

            console.log(newInvitation);

            const prevInvitation = prev?.getInvitation;

            if (
              invitationId !== prevInvitation?.id ||
              newInvitation?.id !== invitationId
            ) {
              return prev;
            }

            if (
              newInvitation?.status === InvitationStatus.Accepted &&
              newInvitation?.session?.id
            ) {
              navigate(SCREENS.SESSION + "/" + newInvitation?.session?.id);
              window.location.reload();
            } else if (newInvitation?.status === InvitationStatus.Pending) {
            } else {
              // DECLINED OR CANCELLED
            }

            return {
              getInvitation: newInvitation,
            };
          } catch (err) {
            console.log(err);
          }
        },
      });

      return () => {
        if (invitationId) {
          unsubscribe();
        }
      };
    }
  }, [invitationId]);

  useEffect(() => {
    console.log(invitationData);

    return () => {
      if (invitationData?.getInvitation?.id) {
        if (
          invitationData?.getInvitation?.status === InvitationStatus.Pending
        ) {
          cancelInvitation();
        }
      }
    };
  }, [invitationData?.getInvitation?.status]);

  function inviteByPass() {
    const availablePasses = userPass?.getMyOwnUserPasses?.edges?.filter(
      (userPass) => !userPass?.sent
    );

    if (!availablePasses || !(availablePasses?.length > 0)) return;

    sendInvitation({
      variables: {
        receiverId: instructorId,
        userPassId: availablePasses?.[availablePasses?.length - 1]?.id,
      },
    });
  }

  function inviteAsSubscriber() {
    const activeSubscription = subscription?.getMySubscriptions?.find(
      (item) => item?.status === SubscriptionStatus.Active
    );

    if (!activeSubscription) return;

    sendInvitation({
      variables: {
        receiverId: instructorId,
        userSubscriptionId: activeSubscription?.id,
      },
    });
  }

  function cancelInvitation() {
    if (!invitationId) return;

    if (invitationData?.getInvitation?.status !== InvitationStatus.Pending)
      return;

    cancelMyPendingInvitation({
      variables: {
        invitationId,
      },
    });
  }

  if (loading || userPassLoading || subscriptionLoading) {
    return <Loading />;
  }

  if (!profile) {
    return (
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="center"
        gap={theme.spacing[32]}
      >
        <Text typography="heading3" color={theme.color.neutral700}>
          로그인하고 상담신청을 해보세요!
        </Text>

        <Button
          disabled={
            !!invitationId &&
            invitationData?.getInvitation?.status === InvitationStatus.Pending
          }
          text={t("invitation.invite")}
          round={false}
          onClick={() => {
            navigate(SCREENS.SIGN_IN);
          }}
        />
      </Flex>
    );
  }

  if (profile?.isSubscriber) {
    return (
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="center"
        gap={theme.spacing[32]}
      >
        <Text typography="heading3" color={theme.color.neutral700}>
          <Trans
            i18nKey="invitation.leftPass"
            values={{ count: profile?.usuableTickets || 0 }}
          />
        </Text>
        <Text
          typography="heading5"
          color={theme.color.neutral800}
          padding={`${theme.spacing[16]}px ${theme.spacing[8]}px`}
          backgroundColor="#f5f5f5"
        >
          {t("invitation.invitationDescription")}
        </Text>
        <Button
          disabled={
            !!invitationId &&
            invitationData?.getInvitation?.status === InvitationStatus.Pending
          }
          text={t("invitation.invite")}
          round={false}
          onClick={() => {
            inviteAsSubscriber();
          }}
        />

        {!!invitationId &&
          invitationData?.getInvitation?.status ===
            InvitationStatus.Pending && (
            <>
              <Text
                typography="heading3"
                color={theme.color.primary1}
                textAlign="center"
              >
                {t("invitation.pendingInvitation")}
              </Text>
              <Button
                disabled={cancelLoading}
                text={t("invitation.cancelInviation")}
                round={false}
                onClick={cancelInvitation}
              />
            </>
          )}
      </Flex>
    );
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      gap={theme.spacing[32]}
    >
      <Text typography="heading3" color={theme.color.neutral700}>
        <Trans
          i18nKey="invitation.leftPass"
          values={{ count: userPass?.getMyOwnUserPasses?.edges?.length || 0 }}
        />
      </Text>
      <Text
        typography="heading5"
        color={theme.color.neutral800}
        padding={`${theme.spacing[16]}px ${theme.spacing[8]}px`}
        backgroundColor="#f5f5f5"
      >
        {t("invitation.invitationDescription")}
      </Text>
      <Button
        disabled={
          !!invitationId &&
          invitationData?.getInvitation?.status === InvitationStatus.Pending
        }
        text={t("invitation.invite")}
        round={false}
        onClick={() => {
          inviteByPass();
        }}
      />

      {!!invitationId &&
        invitationData?.getInvitation?.status === InvitationStatus.Pending && (
          <>
            <Text
              typography="heading3"
              color={theme.color.primary1}
              textAlign="center"
            >
              {t("invitation.pendingInvitation")}
            </Text>
            <Button
              disabled={cancelLoading}
              text={t("invitation.cancelInviation")}
              round={false}
              onClick={cancelInvitation}
            />
          </>
        )}
    </Flex>
  );
}

export default InvitationModal;
