import React from "react";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import { SessionStatus } from "../../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../common/components/Avatar";
import Text from "../../../common/components/Text";
import dayjs from "dayjs";
import Button from "../../../common/components/Button";
import { SCREENS } from "../../../../lib/route/utils/router";

interface MySessionCardProps {
  session: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    password?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    needInterpreter?: boolean | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
  };
}

function MySessionCard({ session }: MySessionCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const { instructor, startAt, endAt } = session;

  if (!startAt) {
    return null;
  }

  return (
    <Flex
      flexDirection={!isMobile ? "row" : "column"}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
      border={`1px solid ${theme.color.neutral800}`}
      backgroundColor={theme.color.neutral200}
      borderRadius={8}
    >
      <Flex
        flex={1}
        alignItems="center"
        gap={theme.spacing[isMobile ? 8 : 24]}
        flexDirection={isMobile ? "column" : "row"}
        marginBottom={isMobile ? theme.spacing[12] : 0}
      >
        <Avatar
          size={isMobile ? 80 : 120}
          uri={instructor?.user?.avatar?.uri}
          initial={instructor?.user?.name?.[0]}
          showOnline={false}
        />
        <Flex
          flexDirection="column"
          flex={1}
          gap={theme.spacing[isMobile ? 8 : 12]}
        >
          <Text typography="heading3" color={theme.color.neutral800}>
            {instructor?.user?.name} {t("common.instructor")}
          </Text>
          <Text typography="body2" color={theme.color.neutral700}>
            {t("mySession.startAt")} {dayjs(startAt).format("YY.MM.DD h:m A")}
          </Text>
          {endAt && (
            <Text typography="body2" color={theme.color.neutral700}>
              {t("mySession.endAt")} {dayjs(endAt).format("YY.MM.DD h:mm A")}
            </Text>
          )}
        </Flex>
      </Flex>
      <Button
        text={t("mySession.viewSession")}
        round={false}
        size="lg"
        onClick={() => navigate(SCREENS.SESSION_HISTORY + "/" + session.id)}
      />
    </Flex>
  );
}

export default MySessionCard;
