import { useGetMyAllergiesQuery } from "../../../../lib/apollo/graphql/generated";

function useAllergyContainer() {
  const { loading, data } = useGetMyAllergiesQuery();

  return {
    models: {
      loading,
      data: data?.getMyAllergies,
    },
  };
}

export default useAllergyContainer;
