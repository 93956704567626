import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";

import {
  GetMySurgerysDocument,
  UserSurgeryInput,
  useDeleteMySurgeryMutation,
  useGetVaccineTypesQuery,
  useUpdateMySurgeryMutation,
} from "../../../../lib/apollo/graphql/generated";
import { SurgeryFormProps } from "./SurgeryForm";
import { useRef, useState } from "react";

function useSurgeryForm({ surgery: _surgery }: SurgeryFormProps) {
  const { i18n } = useTranslation();

  const mediaInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<any>({
    id: _surgery?.id || null,
    surgery: _surgery?.surgery || "",
    part: _surgery?.part || "",
    department: _surgery?.department || "",
    mediaState: null,
  });

  const [updateMySurgery, { loading: updateLoading }] =
    useUpdateMySurgeryMutation({
      onCompleted: () => {
        setState({
          id: _surgery?.id || null,
          surgery: _surgery?.surgery || "",
          part: _surgery?.part || "",
          department: _surgery?.department || "",
          mediaState: null,
        });
      },
      refetchQueries: !_surgery
        ? () => [
            {
              query: GetMySurgerysDocument,
            },
          ]
        : undefined,
    });
  const [deleteMySurgery] = useDeleteMySurgeryMutation({
    refetchQueries: () => [
      {
        query: GetMySurgerysDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onMediaChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (!files) return;

    const file = files?.[0];

    setState((prev: any) => ({
      ...prev,
      mediaState: file,
    }));
  }

  function onDeleteClick() {
    deleteMySurgery({
      variables: {
        userSurgeryId: _surgery?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { surgery, part, department } = state;

    return !surgery || !department || !part || updateLoading;
  }

  async function onAddClick() {
    if (isSubmitDisabled()) return;

    const { surgery, part, department, mediaState } = state;

    const userSurgeryInput: UserSurgeryInput = {
      id: _surgery?.id,
      surgery,
      part,
      department,
    };

    if (mediaState) {
      userSurgeryInput.mediaInput = { file: mediaState };
    }

    updateMySurgery({
      variables: {
        userSurgeryInput,
      },
    });
  }

  return {
    refs: {
      mediaInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      onMediaChange,
      onDeleteClick,
      onAddClick,
    },
  };
}

export default useSurgeryForm;
