import React from "react";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import { useAuthContext } from "../../../common/provider/AuthProvider";
import Section from "../../../common/components/Section";
import theme from "../../../../lib/theme";
import MyPageHeader from "../../common/components/MyPageHeader";
import Divider from "../../../common/components/Divider";
import useMySubscriptionContainer from "./MySubscriptionContainer.hook";
import Loading from "../../../common/components/Loading";
import Text from "../../../common/components/Text";
import Spacer from "../../../common/components/Spacer";
import Button from "../../../common/components/Button";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";
import MySubscriptionCard from "../components/MySubscriptionCard";
import Flex from "../../../common/components/Flex";

function MySubscriptionContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const { profile } = useAuthContext();

  const {
    models: { loading, data },
  } = useMySubscriptionContainer();

  return (
    <Section
      innerStyle={{
        width: "100%",
        maxWidth: 794,
        paddingTop: theme.spacing[isMobile ? 16 : 48],
        paddingBottom: theme.spacing[isMobile ? 16 : 48],
      }}
    >
      <MyPageHeader title={t("myPage.subscriptionTitle")} />
      <Divider full size={1} />
      <Spacer size={24} />
      {loading ? (
        <Loading />
      ) : !!data && data?.length > 0 ? (
        <Flex width="100%" gap={theme.spacing[24]} flexDirection="column">
          {data?.map((subscrpition) => (
            <MySubscriptionCard
              key={subscrpition?.id}
              subscription={subscrpition!}
            />
          ))}
        </Flex>
      ) : (
        <>
          <Text typography="heading4" marginBottom={theme.spacing[24]}>
            {t("myPage.subscriptionNone")}
          </Text>
          <Button
            size="lg"
            text={t("myPage.goToSubscribe")}
            onClick={() => navigate(SCREENS.PURCHASE + SCREENS.ITEMS)}
          />
        </>
      )}
    </Section>
  );
}

export default MySubscriptionContainer;
