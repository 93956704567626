import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import PHROptionSelector from "../../phr/component/PHROptionSelector";
import useMedicineForm from "./MedicineForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";
import Modal from "../../../common/components/Modal";
import SearchMedicineModal from "./SearchMedicineModal";

export interface MedicineFormProps {
  medicine: {
    __typename?: "UserMedicine";
    id: string;
    medicine?: string | null;
    dosage?: string | null;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null;
}

function MedicineForm({ medicine }: MedicineFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    models: { state, showModal },
    operations: {
      onInputChange,
      onDeleteClick,
      onAddClick,
      toggleModal,
      onMedicineSearched,
      closeModal,
    },
  } = useMedicineForm({ medicine });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      backgroundColor={
        !medicine ? theme.color.neutralWhite : theme.color.secondary3
      }
      padding={theme.spacing[8]}
    >
      {!medicine && <PHRAddButton text="addMedicine" onClick={onAddClick} />}
      {!!medicine && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <Input
        rightButton={{
          text: t("phr.searchMedicine"),
          onClick: toggleModal,
        }}
        label={t("phr.medicineName")}
        value={state?.medicine}
        placeholder={t("phr.medicineNamePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "medicine")
        }
      />
      <Input
        label={t("phr.dosage")}
        value={state?.dosage}
        placeholder={t("phr.dosagePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "dosage")
        }
      />
      <Input
        label={t("phr.medicineDescription")}
        value={state?.description}
        placeholder={t("phr.medicineDescriptionPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "description")
        }
      />

      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={closeModal}
          onClose={closeModal}
        >
          <SearchMedicineModal
            onMedicineSearched={onMedicineSearched}
            onClose={closeModal}
          />
        </Modal>
      )}
    </Flex>
  );
}

export default MedicineForm;
