import React from "react";
import Text from "../../../common/components/Text";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";

function PHRPathname({ name }: { name: string }) {
  const { t } = useTranslation();

  return (
    <Text typography="heading3" color={theme.color.neutral800}>
      {t("phr." + name)}
    </Text>
  );
}

export default PHRPathname;
