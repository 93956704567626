import React from "react";
import { useTranslation } from "react-i18next";
import itemIcon from "../../../../assets/icon/image_search.png";
import theme from "../../../../lib/theme";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import { getNumbersWithCommas } from "../../../common/utils/getNumbersWithCommas";

interface PurchaseItemCardProps {
  name: string;
  price: number | string;
  summary?: string | null;
  description?: string | null;
  isSelected?: boolean;
  onList?: boolean;
  onClick?: () => void;
}

function PurchaseItemCard({
  name,
  price,
  summary,
  description,
  isSelected,
  onList = true,
  onClick,
}: PurchaseItemCardProps) {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      padding={theme.spacing[24]}
      gap={theme.spacing[8]}
      border={`1px solid #DEE8E8`}
      borderRadius={20}
      width="100%"
      maxWidth={392}
    >
      <img src={itemIcon} style={{ width: 68, height: 72 }} />
      <Text typography="heading3" color={theme.color.neutral900}>
        {name}
      </Text>
      {!!summary && (
        <Text typography="body1Bold" color={theme.color.primary2}>
          {t("common.monthly")} {t("common.currencyUnit")}
          {getNumbersWithCommas(price)} / {summary}
        </Text>
      )}
      {!!description && (
        <Text typography="body2Bold" color={theme.color.primary3}>
          {description}
        </Text>
      )}
      {onList && (
        <Button
          text={t("common.choose")}
          size="lg"
          variant="outline"
          round={false}
          borderColor="neutral900"
          bgColor={!isSelected ? "tertiary2" : "tertiary1"}
          fontColor="neutral900"
          onClick={onClick}
        />
      )}
    </Flex>
  );
}

export default PurchaseItemCard;
