import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { firebaseSignIn } from "../../../../lib/firebase";
import { SCREENS } from "../../../../lib/route/utils/router";

function useSignInContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });
  const [loggingIn, setLoggingIn] = useState(false);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFocus(key: keyof typeof state) {
    if (!!state[key]) {
      setState((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  }

  function onBlur(key: keyof typeof state) {
    if (key === "emailError" && !validator.isEmail(state?.email)) {
      setState((prev) => ({
        ...prev,
        emailError: t("error.emailError"),
      }));
    }

    if (key === "passwordError" && state?.password?.length < 6) {
      setState((prev) => ({
        ...prev,
        passwordError: t("error.passwordLengthError"),
      }));
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = e;

    if (key === "Enter") {
      onSubmit();
    }
  }

  function isSubmitDisabled() {
    const { email, emailError, password, passwordError } = state;

    return !!emailError || !!passwordError || loggingIn;
  }

  async function onSubmit() {
    setLoggingIn(true);
    try {
      const { email, password } = state;
      await firebaseSignIn(email, password);
      navigate(SCREENS.HOME);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        emailError: t("error.signInError"),
        passwordError: t("error.signInError"),
      }));
    }
    setLoggingIn(false);
  }

  return {
    models: {
      state,
      loggingIn,
    },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useSignInContainer;
