import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import PHROptionSelector from "../../phr/component/PHROptionSelector";
import useVaccineForm from "./VaccineForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";
import dayjs from "dayjs";

export interface VaccineFormProps {
  vaccine: {
    __typename?: "UserVaccine";
    id: string;
    level?: string | null;
    lastVaccinatedAt?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    vaccineType: { __typename?: "VaccineType"; id: string; name: string };
  } | null;
}

function VaccineForm({ vaccine }: VaccineFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    models: { loading, data, state },
    operations: { onInputChange, onTypeChange, onDeleteClick, onAddClick },
  } = useVaccineForm({ vaccine });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      backgroundColor={
        !vaccine ? theme.color.neutralWhite : theme.color.secondary3
      }
      padding={theme.spacing[8]}
    >
      {!vaccine && <PHRAddButton text="vaccineTitle" onClick={onAddClick} />}
      {!!vaccine && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <PHROptionSelector
        label="vaccineTypeTitle"
        options={
          !!data
            ? data?.map((option) => ({
                id: option?.id!,
                name: option?.name!,
                isSelected: state?.typeId === option?.id,
              }))
            : undefined
        }
        onClick={(id: string) => onTypeChange(id)}
      />
      <Input
        label={t("phr.level")}
        value={state?.level}
        placeholder={t("phr.levelPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "level")
        }
      />
      <Input
        type="date"
        max={dayjs().format("YYYY-MM-DD")}
        label={t("phr.lastVaccinatedAt")}
        value={state?.lastVaccinatedAt}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "lastVaccinatedAt")
        }
      />
    </Flex>
  );
}

export default VaccineForm;
