import React, { memo } from "react";
import Flex from "../../common/components/Flex";
import Grid from "../../common/components/Grid";
import theme from "../../../lib/theme";
import { useAuthContext } from "../../common/provider/AuthProvider";
import { useTranslation } from "react-i18next";
import SessionUserCard from "./SessionUserCard";
import { useParams } from "react-router-dom";
import { useGetSessionQuery } from "../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

interface SessionUsersProps {
  isHistory?: boolean;
}

function SessionUsers({ isHistory = false }: SessionUsersProps) {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const params = useParams();
  const { isMobile } = useScreenWidthContext();

  const sessionId = params?.sessionId;

  const { loading, data } = useGetSessionQuery({
    fetchPolicy: "network-only",
    variables: {
      sessionId: sessionId!,
    },
  });

  return (
    <Grid
      flex={1}
      height="100%"
      gridTemplateRows="repeat(3, 1fr)"
      gap={theme.spacing[12]}
      backgroundColor={
        isMobile ? theme.color.neutralWhite : theme.color.primary1
      }
      padding={theme.spacing[8]}
    >
      {!loading &&
        data?.getSession?.users?.map(
          (sessionUser) =>
            sessionUser?.user?.id !== profile?.id && (
              <SessionUserCard
                key={sessionUser?.id}
                sessionUser={sessionUser}
              />
            )
        )}
    </Grid>
  );
}

export default SessionUsers;
