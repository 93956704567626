import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import PHROptionSelector from "../../phr/component/PHROptionSelector";
import useChronicDiseaseForm from "./ChronicDiseaseForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";

export interface ChronicDiseaseFormProps {
  disease: {
    __typename?: "UserChronicDisease";
    id: string;
    etc?: string | null;
    medicine?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "ChronicDiseaseType"; id: string; name: string };
  } | null;
}

function ChronicDiseaseForm({ disease }: ChronicDiseaseFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    models: { loading, data, state },
    operations: { onInputChange, onTypeChange, onDeleteClick, onAddClick },
  } = useChronicDiseaseForm({ disease });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      backgroundColor={
        !disease ? theme.color.neutralWhite : theme.color.secondary3
      }
      padding={theme.spacing[8]}
    >
      {!disease && <PHRAddButton text="chronicDisease" onClick={onAddClick} />}
      {!!disease && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <PHROptionSelector
        label="chronicDiseaseOptionTitle"
        options={
          !!data
            ? data?.map((option) => ({
                id: option?.id!,
                name: option?.name!,
                isSelected: state?.typeId === option?.id,
              }))
            : undefined
        }
        onClick={(id: string) => onTypeChange(id)}
      />
      <Input
        label={t("phr.otherDisease")}
        value={state?.etc}
        placeholder={t("phr.otherDiseasePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "etc")
        }
      />
      <Input
        label={t("phr.medicine")}
        value={state?.medicine}
        placeholder={t("phr.medicinePlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "medicine")
        }
      />
      <Input
        label={t("phr.firstAid")}
        value={state?.firstAid}
        placeholder={t("phr.firstAidPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "firstAid")
        }
      />
    </Flex>
  );
}

export default ChronicDiseaseForm;
