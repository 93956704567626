import React from "react";
import Flex from "../../../common/components/Flex";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import theme from "../../../../lib/theme";
import PHROptionSelector from "../../phr/component/PHROptionSelector";
import useAllergyForm from "./AllergyForm.hook";
import Input from "../../../common/components/Input";
import { useTranslation } from "react-i18next";
import PHRAddButton from "../../phr/component/PHRAddButton";
import PHRDeleteButton from "../../phr/component/PHRDeleteButton";
import Button from "../../../common/components/Button";

export interface AllergyFormProps {
  allergy: {
    __typename?: "UserAllergy";
    id: string;
    summary?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "AllergyType"; id: string; name: string };
    symptoms?: Array<{
      __typename?: "AllergySymptom";
      id: string;
      name: string;
    } | null> | null;
  } | null;
}

function AllergyForm({ allergy }: AllergyFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    models: { loading, data, symptoms, state },
    operations: {
      onInputChange,
      onTypeChange,
      onSymptomClick,
      onDeleteClick,
      onAddClick,
    },
  } = useAllergyForm({ allergy });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={isMobile ? theme.spacing[12] : theme.spacing[24]}
      padding={theme.spacing[8]}
      backgroundColor={
        !allergy ? theme.color.neutralWhite : theme.color.secondary3
      }
    >
      {!allergy && <PHRAddButton text="allergyTitle" onClick={onAddClick} />}
      {!!allergy && (
        <Flex width="100%" alignItems="center" justifyContent="flex-end">
          <PHRDeleteButton onClick={onDeleteClick} />
        </Flex>
      )}

      <PHROptionSelector
        label="allergyTypeTitle"
        options={
          !!data
            ? data?.map((option) => ({
                id: option?.id!,
                name: option?.name!,
                isSelected: state?.typeId === option?.id,
              }))
            : undefined
        }
        onClick={(id: string) => onTypeChange(id)}
      />

      <PHROptionSelector
        label="allergySymptomTitle"
        options={
          !!symptoms
            ? symptoms?.map((option) => ({
                id: option?.id!,
                name: option?.name!,
                isSelected: state?.symptomIds.includes(option?.id),
              }))
            : undefined
        }
        onClick={(id: string) => onSymptomClick(id)}
      />

      <Input
        label={t("phr.allergySummary")}
        value={state?.summary}
        placeholder={t("phr.allergySummaryPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "summary")
        }
      />
      <Input
        label={t("phr.firstAid")}
        value={state?.firstAid}
        placeholder={t("phr.firstAidPlaceholder")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "firstAid")
        }
      />
    </Flex>
  );
}

export default AllergyForm;
