import React from "react";
import Layout from "../../common/components/Layout";
import Section from "../../common/components/Section";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import { useTranslation } from "react-i18next";
import Divider from "../../common/components/Divider";
import Spacer from "../../common/components/Spacer";
import SessionUsers from "../../session/components/SessionUsers";
import SessionTopBar from "../../session/components/SessionTopBar";
import SessionChat from "../../session/components/SessionChat";
import SessionChatFooter from "../../session/components/SessionChatFooter";
import { useGetSessionQuery } from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import Loading from "../../common/components/Loading";
import SessionHistoryController from "../components/SessionHistoryController";
import SessionReport from "../../session/components/SessionReport";

function SessionHistoryContainer() {
  const { t } = useTranslation();
  const params = useParams();

  const sessionId = params?.sessionId!;

  const { loading, data } = useGetSessionQuery({
    variables: {
      sessionId,
    },
  });

  if (loading) return <Loading />;

  const session = data?.getSession;

  return (
    <Layout>
      <Section innerStyle={{ height: "90vh", position: "relative" }}>
        <Flex
          width="100%"
          height="100%"
          position="relative"
          flexDirection="column"
        >
          <Text
            typography="heading2"
            color={theme.color.primary1}
            marginBottom={theme.spacing[16]}
          >
            {t("session.sessionTitle")}
          </Text>
          <Divider size={1} full />
          <Spacer size={20} />
          <Flex
            flex={1}
            width="100%"
            alignItems="flex-start"
            position="relative"
            gap={theme.spacing[12]}
            overflowY="hidden"
          >
            <SessionUsers />
            <Flex
              flex={4}
              height="100%"
              flexDirection="column"
              position="relative"
            >
              <SessionTopBar
                instructor={session?.instructor}
                isHistory={true}
              />
              <SessionHistoryController />
              <SessionChat
                sessionId={session?.id!}
                sessionAt={session?.createdAt}
              />
              <SessionChatFooter sessionId={session?.id!} isHistory />
            </Flex>

            <Flex flex={1} height="100%">
              <SessionReport report={session?.report} />
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </Layout>
  );
}

export default SessionHistoryContainer;
