import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { USER_PASS_TAB } from "../../../../utils/constants";
import {
  UserPassStatus,
  useGetMyOwnUserPassesQuery,
} from "../../../../lib/apollo/graphql/generated";

function useMyPassesContainer() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [tab, setTab] = useState(USER_PASS_TAB.AVAILABLE);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetMyOwnUserPassesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      status:
        tab === USER_PASS_TAB.AVAILABLE
          ? [UserPassStatus.Received, UserPassStatus.Purchased]
          : [UserPassStatus.Cancelled, UserPassStatus.Used],
      first: 2,
    },
  });

  function onTabChange(newTab: string) {
    if (tab !== newTab) {
      setTab(newTab);
    }
  }

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getMyOwnUserPasses?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          status:
            tab === USER_PASS_TAB.AVAILABLE
              ? [UserPassStatus.Received, UserPassStatus.Purchased]
              : [UserPassStatus.Cancelled, UserPassStatus.Used],
          first: 2,
          after: data?.getMyOwnUserPasses?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getMyOwnUserPasses?.edges,
      tab,
    },
    operations: {
      onTabChange,
    },
  };
}

export default useMyPassesContainer;
