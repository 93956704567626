import { useTranslation } from "react-i18next";
import {
  GetMyChronicDiseasesDocument,
  useDeleteMyChronicDiseaseMutation,
  useGetChronicDiseaseTypesQuery,
  useUpdateMyChronicDiseaseMutation,
} from "../../../../lib/apollo/graphql/generated";
import { ChronicDiseaseFormProps } from "./ChronicDiseaseForm";
import { useState } from "react";

function useChronicDiseaseForm({ disease }: ChronicDiseaseFormProps) {
  const { i18n } = useTranslation();

  const [state, setState] = useState({
    id: disease?.id || null,
    typeId: disease?.type?.id || null,
    etc: disease?.etc || "",
    medicine: disease?.medicine || "",
    firstAid: disease?.firstAid || "",
  });

  const { loading, data } = useGetChronicDiseaseTypesQuery({
    variables: {
      language: i18n.resolvedLanguage || "ko",
      activeOnly: true,
    },
  });

  const [updateMyChronicDisease] = useUpdateMyChronicDiseaseMutation({
    onCompleted: () => {
      setState({
        id: disease?.id || null,
        typeId: disease?.type?.id || null,
        etc: disease?.etc || "",
        medicine: disease?.medicine || "",
        firstAid: disease?.firstAid || "",
      });
    },
    refetchQueries: !disease
      ? () => [
          {
            query: GetMyChronicDiseasesDocument,
          },
        ]
      : undefined,
  });
  const [deleteMyChronicDisease] = useDeleteMyChronicDiseaseMutation({
    refetchQueries: () => [
      {
        query: GetMyChronicDiseasesDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onTypeChange(id: string) {
    setState((prev) => ({
      ...prev,
      typeId: id,
    }));
  }

  function onDeleteClick() {
    deleteMyChronicDisease({
      variables: {
        userChronicDiseaseId: disease?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { typeId, etc, medicine, firstAid } = state;

    return !typeId || !medicine || !firstAid;
  }

  function onAddClick() {
    if (isSubmitDisabled()) return;

    const { typeId, etc, medicine, firstAid } = state;

    updateMyChronicDisease({
      variables: {
        userChronicDiseaseInput: {
          id: disease?.id,
          typeId: typeId!,
          etc,
          medicine,
          firstAid,
        },
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getChronicDiseaseTypes,
      state,
    },
    operations: {
      onInputChange,
      onTypeChange,
      onDeleteClick,
      onAddClick,
    },
  };
}

export default useChronicDiseaseForm;
