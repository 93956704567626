import React, { useState } from "react";
import Flex from "../../common/components/Flex";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import theme from "../../../lib/theme";
import Section from "../../common/components/Section";
import Text from "../../common/components/Text";
import faqIcon from "../../../assets/icon/faq.png";
import arrowIcon from "../../../assets/icon/arrow_right_gray.png";
import { useGetFaQsQuery } from "../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";

function HomeFaq() {
  const { isMobile } = useScreenWidthContext();
  const { t } = useTranslation();

  const { loading, data } = useGetFaQsQuery({
    variables: {
      activeOnly: true,
    },
  });

  if (loading) return <div />;

  return (
    <Section
      wrapperStyle={{
        borderTop: `1px solid ${theme.color.neutral800}`,
      }}
    >
      <Flex
        flexDirection={isMobile ? "column" : "row"}
        alignItems="flex-start"
        width="100%"
        gap={theme.spacing[20]}
        padding={`0px ${theme.spacing[10]}px`}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing[20]}
          flex={1}
        >
          <Text
            typography={isMobile ? "heading3" : "heading2"}
            color={theme.color.neutral900}
          >
            {t("home.mainFaq")}
          </Text>
          <Text typography="heading4" color={theme.color.neutral600}>
            {t("home.mainFaqDescription")}
          </Text>
          <img src={faqIcon} style={{ height: 80, objectFit: "contain" }} />
        </Flex>
        <Flex flex={1} flexDirection="column" alignItems="flex-start">
          {data?.getFAQs?.map((faq) => (
            <FaqCard key={faq?.id} faq={faq!} />
          ))}
        </Flex>
      </Flex>
    </Section>
  );
}

export default HomeFaq;

interface FaqCardProps {
  faq: {
    __typename?: "FAQ";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    order: number;
  };
}

function FaqCard({ faq }: FaqCardProps) {
  const [show, setShow] = useState(false);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      borderBottom={`1px solid ${theme.color.neutral300}`}
      padding={`${theme.spacing[20]}px ${theme.spacing[16]}px`}
      gap={theme.spacing[24]}
    >
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setShow(!show)}
        gap={theme.spacing[8]}
      >
        <Text typography={show ? "heading4" : "body1"}>{faq?.question}</Text>
        <img
          src={arrowIcon}
          style={{
            width: 24,
            height: 24,
            transform: show ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        />
      </Flex>
      {show && (
        <Text typography="body2" color={theme.color.neutral600}>
          {faq?.answer}
        </Text>
      )}
    </Flex>
  );
}
