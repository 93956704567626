import React from "react";
import Text from "./Text";
import theme from "../../../lib/theme";
import Flex from "./Flex";

interface InputLabelProps {
  label?: string | number;
  rightButton?: {
    text?: string;
    onClick?: () => void;
  };
}

function InputLabel({ label, rightButton }: InputLabelProps) {
  return (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      <Text typography="body1Bold" color={theme.color.neutral800}>
        {label}
      </Text>

      {!!rightButton && (
        <div
          style={{ cursor: "pointer" }}
          onClick={rightButton?.onClick && rightButton?.onClick}
        >
          <Text typography="body2" color={theme.color.primary1}>
            {rightButton?.text || ""}
          </Text>
        </div>
      )}
    </Flex>
  );
}

export default InputLabel;
