import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useZoomContext } from "../provider/ZoomProvider";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import Divider from "../../common/components/Divider";
import theme from "../../../lib/theme";
import Spacer from "../../common/components/Spacer";
import SessionUsers from "./SessionUsers";
import SessionTopBar from "./SessionTopBar";
import SessionController from "./SessionController";
import SessionChat from "./SessionChat";
import SessionChatFooter from "./SessionChatFooter";
import { generateSignature } from "../helpers/generateSignature";
import { useAuthContext } from "../../common/provider/AuthProvider";
import ZoomVideo, { ConnectionState, ReconnectReason } from "@zoom/videosdk";
import { SessionStatus } from "../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";
import Loading from "../../common/components/Loading";
import { MediaStream, ZoomMediaContext } from "../provider/ZoomMediaProvider";
import { produce } from "immer";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

interface SessionZoomProps {
  signature: string;
  session: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    password?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    needInterpreter?: boolean | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload;
      break;
    }
    case "audio-decode": {
      draft.audio.decode = action.payload;
      break;
    }
    case "video-encode": {
      draft.video.encode = action.payload;
      break;
    }
    case "video-decode": {
      draft.video.decode = action.payload;
      break;
    }
    case "share-encode": {
      draft.share.encode = action.payload;
      break;
    }
    case "share-decode": {
      draft.share.decode = action.payload;
      break;
    }
    case "reset-media": {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

function SessionZoom({ signature, session }: SessionZoomProps) {
  const zoomClient = useZoomContext();
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const { profile } = useAuthContext();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<string>("closed");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const mediaContext = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream]
  );

  useEffect(() => {
    const init = async () => {
      await zoomClient.init("en-US", `${(window as any).location.origin}/lib`, {
        stayAwake: true,
        leaveOnPageUnload: true,
      });
      try {
        await zoomClient
          .join(session?.id, signature, profile?.name!, session?.password!)
          .catch((e) => {
            console.log("join error", e);
          });
        const stream = zoomClient.getMediaStream();
        setMediaStream(stream);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
      }
    };

    if (status !== "connected") {
      init();
    }
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    signature,
    zoomClient,
    session?.id,
    profile?.name,
    session?.password,
    status,
  ]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setLoading(true);
        setStatus("connecting");
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
        } else if (reason === ReconnectReason.BackToMainSession) {
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected");

        (window as any).zoomClient = zoomClient;
        (window as any).mediaStream = zoomClient.getMediaStream();

        console.log("getSessionInfo", zoomClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed");
      }
    },
    [zoomClient]
  );

  const onDialoutChange = useCallback((payload: any) => {
    console.log("onDialoutChange", payload);
  }, []);

  const onAudioMerged = useCallback((payload: any) => {
    console.log("onAudioMerged", payload);
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === "closed") {
      setLoading(true);
      await zoomClient.join(
        session?.id,
        signature,
        profile?.name!,
        session?.password!
      );
      setLoading(false);
    } else if (status === "connected") {
      await zoomClient.leave();
    }
  }, [
    zoomClient,
    status,
    session?.id,
    signature,
    profile?.name,
    session?.password,
  ]);

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === "success" });
  }, []);

  useEffect(() => {
    zoomClient.on("connection-change", onConnectionChange);
    zoomClient.on("dialout-state-change", onDialoutChange);
    zoomClient.on("merged-audio", onAudioMerged);
    zoomClient.on("media-sdk-change", onMediaSDKChange);

    return () => {
      zoomClient.off("connection-change", onConnectionChange);
      zoomClient.off("dialout-state-change", onDialoutChange);
      zoomClient.off("merged-audio", onAudioMerged);
      zoomClient.off("media-sdk-change", onMediaSDKChange);
    };
  }, [zoomClient, onConnectionChange, onDialoutChange, onAudioMerged]);

  if (loading || status !== "connected") return <Loading />;

  return (
    <ZoomMediaContext.Provider value={mediaContext}>
      <Flex
        width="100%"
        height="100%"
        position="relative"
        flexDirection="column"
      >
        <Text
          typography="heading2"
          color={theme.color.primary1}
          marginBottom={theme.spacing[16]}
        >
          {t("session.sessionTitle")}
        </Text>
        <Divider size={1} full />
        <Spacer size={20} />
        <Flex
          flex={1}
          width="100%"
          alignItems="flex-start"
          position="relative"
          gap={theme.spacing[12]}
          overflowY="hidden"
        >
          {!isMobile && <SessionUsers />}
          <Flex
            flex={4}
            height="100%"
            flexDirection="column"
            position="relative"
          >
            <SessionTopBar
              instructor={session?.instructor}
              extensions={session?.extensions}
            />
            <SessionController
              sessionId={session?.id!}
              joined={status === "connected"}
            />
            <SessionChat
              sessionId={session?.id!}
              sessionAt={session?.createdAt}
            />
            <SessionChatFooter sessionId={session?.id!} />
          </Flex>
        </Flex>
      </Flex>
    </ZoomMediaContext.Provider>
  );
}

export default memo(SessionZoom);
