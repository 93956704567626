import { useEffect } from "react";
import {
  UpdatedOnlineInstructorDocument,
  UpdatedOnlineInstructorSubscription,
  UpdatedOnlineInstructorSubscriptionVariables,
  useGetAllInstructorsQuery,
  useGetOnlineInstructorsQuery,
} from "../../../lib/apollo/graphql/generated";

function useHomeAllInstructors() {
  const { loading, data, subscribeToMore } = useGetAllInstructorsQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const unsubscribeOnlineInstructors = subscribeToMore<
      UpdatedOnlineInstructorSubscription,
      UpdatedOnlineInstructorSubscriptionVariables
    >({
      document: UpdatedOnlineInstructorDocument,
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newInstructor = subscriptionData?.data?.updatedOnlineInstructor;

          if (!newInstructor) return prev;

          const prevInstructors = prev?.getAllInstructors || [];

          let editedInstructors = [...prevInstructors];

          const selectedInstructorIndex = editedInstructors?.findIndex(
            (instructor) => instructor?.id === newInstructor?.id
          );

          editedInstructors[selectedInstructorIndex] = newInstructor;

          return {
            getAllInstructors: editedInstructors,
          };
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      unsubscribeOnlineInstructors();
    };
  }, []);

  return {
    models: {
      loading,
      data: data?.getAllInstructors,
    },
  };
}

export default useHomeAllInstructors;
