import React from "react";
import {
  SubscriptionStatus,
  useRequestCancelSubscriptionMutation,
  useRevokeSubscriptionMutation,
} from "../../../../lib/apollo/graphql/generated";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";
import Text from "../../../common/components/Text";
import dayjs from "dayjs";
import { getNumbersWithCommas } from "../../../common/utils/getNumbersWithCommas";
import Badge from "../../../common/components/Badge";
import Button from "../../../common/components/Button";
import { toast } from "react-toastify";

interface MySubscriptionCardProps {
  subscription: {
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: {
      __typename?: "SubscriptionPlan";
      id: string;
      name: string;
      tickets: number;
      finalPrice?: number;
    } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  };
}

function MySubscriptionCard({ subscription }: MySubscriptionCardProps) {
  const { t } = useTranslation();

  const { from, to, nextPayAt, status, subscriptionPlan, id } = subscription;

  const [requestCancelSubscription] = useRequestCancelSubscriptionMutation({
    onCompleted: (data) => {
      if (
        data?.requestCancelSubscription?.status ===
        SubscriptionStatus?.CancelRequested
      ) {
        toast(t("mySubscription.cancelRequested"));
      }
    },
  });

  const [revokeSubscription] = useRevokeSubscriptionMutation({
    onCompleted: (data) => {
      if (data?.revokeSubscription?.status === SubscriptionStatus?.Active) {
        toast(t("mySubscription.revoked"));
      }
    },
  });

  function onRequestCancel() {
    if (status === SubscriptionStatus.Active) {
      const confirm = window.confirm(t("mySubscription.requestCancel"));

      if (confirm) {
        requestCancelSubscription({
          variables: {
            subscriptionId: id,
          },
        });
      }
    } else {
      if (subscription?.billing?.id) {
        revokeSubscription({
          variables: {
            subscriptionId: id,
            newBillingId: subscription?.billing?.id!,
          },
        });
      }
    }
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
      border={`1px solid ${theme.color.neutral800}`}
      backgroundColor="white"
      borderRadius={8}
    >
      <Text typography="heading3" color={theme.color.neutral800}>
        {subscriptionPlan?.name}
      </Text>
      <Text typography="body1Bold" color={theme.color.neutral700}>
        {dayjs(from).format("YYYY.M.D")}
        {status !== SubscriptionStatus.Active
          ? !!to
            ? " ~ " + dayjs(to).format("YYYY.M.D")
            : ""
          : " ~ " + t("mySubscription.present")}
      </Text>
      <Text typography="body2" color={theme.color.neutral700}>
        {getNumbersWithCommas(subscriptionPlan?.finalPrice!)}
        {t("common.currencyUnitText")}
      </Text>
      <Badge
        text={
          t("common.ticketsCount") +
          " " +
          subscriptionPlan?.tickets +
          t("common.ticketsCountUnit")
        }
        backgroundColor="primary4"
        fontColor="neutral900"
      />
      <Flex marginTop={theme.spacing[12]}>
        <Button
          text={
            subscription?.status === SubscriptionStatus.Active
              ? t("mySubscription.cancel")
              : subscription?.status === SubscriptionStatus.CancelRequested
              ? t("mySubscription.requestedCancel")
              : t("mySubscription.cancelled")
          }
          disabled={
            subscription?.status === SubscriptionStatus.Cancelled ||
            subscription?.status === SubscriptionStatus.Expired
          }
          size="lg"
          round={false}
          onClick={onRequestCancel}
        />
      </Flex>
    </Flex>
  );
}

export default MySubscriptionCard;
