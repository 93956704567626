import React from "react";
import { useTranslation } from "react-i18next";
import HomeAllInstructors from "./HomeAllInstructors";
import useHomeInstructorList from "./HomeInstructorList.hook";
import HomeOfflineInstructors from "./HomeOfflineInstructors";
import HomeOnlineInstructors from "./HomeOnlineInstructors";
import theme from "../../../lib/theme";
import { HOME_TAB } from "../../../utils/constants";
import Button from "../../common/components/Button";
import Flex from "../../common/components/Flex";
import Section from "../../common/components/Section";
import Text from "../../common/components/Text";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

function HomeInstructorList() {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const {
    models: { currentTab },
    operations: { onTabSelect },
  } = useHomeInstructorList();

  return (
    <Section>
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        padding={`${theme.spacing[24]}px ${theme.spacing[10]}px`}
        border={`1px solid ${theme.color.neutral900}`}
        backgroundColor={theme.color.secondary1}
        gap={theme.spacing[isMobile ? 12 : 20]}
      >
        <Text
          typography="heading2"
          color={theme.color.neutral700}
          textAlign="center"
        >
          {t("home.mainSummary")}
        </Text>

        <Text
          typography="body1"
          color={theme.color.neutral900}
          textAlign="center"
        >
          {t("home.mainListTitle")}
        </Text>

        <Flex
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[16]}
          width="100%"
          backgroundColor={theme.color.secondary1}
        >
          {Object.values(HOME_TAB).map((tab) => {
            const isSelected = tab === currentTab;
            return (
              <Button
                key={tab}
                round={false}
                text={`#${tab}`}
                size={isMobile ? "sm" : "lg"}
                variant={isSelected ? "contained" : "outline"}
                bgColor={isSelected ? "secondary3" : "transparent"}
                fontColor="primary1"
                borderColor="primary1"
                onClick={() => onTabSelect(tab)}
              />
            );
          })}
        </Flex>

        {currentTab === HOME_TAB.ONLINE ? (
          <HomeOnlineInstructors />
        ) : currentTab === HOME_TAB.OFFLINE ? (
          <HomeOfflineInstructors />
        ) : (
          <HomeAllInstructors />
        )}
      </Flex>
    </Section>
  );
}

export default HomeInstructorList;
