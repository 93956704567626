import { useGetMyChronicDiseasesQuery } from "../../../../lib/apollo/graphql/generated";

function useChronicDiseaseContainer() {
  const { loading, data } = useGetMyChronicDiseasesQuery();

  return {
    models: {
      loading,
      data: data?.getMyChronicDiseases,
    },
  };
}

export default useChronicDiseaseContainer;
