import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import doneImage from "../../../../assets/image/purchase_done.png";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Layout from "../../../common/components/Layout";
import Section from "../../../common/components/Section";
import Text from "../../../common/components/Text";

function PurchaseDoneContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout>
      <Section>
        <Flex
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing[48]}
          width="100%"
        >
          <img
            src={doneImage}
            style={{ width: 212, height: 126, objectFit: "contain" }}
          />
          <Flex
            flexDirection="column"
            alignItems="center"
            gap={theme.spacing[24]}
          >
            <Text typography="heading3" color={theme.color.neutral800}>
              {t("purchase.doneTitle")}
            </Text>
            <Text typography="body2" color={theme.color.neutral600}>
              {t("purchase.doneSummary")}
            </Text>
          </Flex>
          <Button
            text={t("purchase.viewAvailableInstructors")}
            round={false}
            size="lg"
            onClick={() => navigate(SCREENS.HOME)}
          />
        </Flex>
      </Section>
    </Layout>
  );
}

export default PurchaseDoneContainer;
