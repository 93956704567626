import { useTranslation } from "react-i18next";
import {
  GetMyMedicinesDocument,
  useDeleteMyMedicineMutation,
  useGetChronicDiseaseTypesQuery,
  useUpdateMyMedicineMutation,
} from "../../../../lib/apollo/graphql/generated";
import { MedicineFormProps } from "./MedicineForm";
import { useState } from "react";

function useMedicineForm({ medicine: _medicine }: MedicineFormProps) {
  const { i18n } = useTranslation();

  const [state, setState] = useState({
    id: _medicine?.id || null,
    dosage: _medicine?.dosage || "",
    medicine: _medicine?.medicine || "",
    description: _medicine?.description || "",
  });
  const [showModal, setShowModal] = useState(false);

  const [updateMyMedicine] = useUpdateMyMedicineMutation({
    onCompleted: () => {
      setState({
        id: _medicine?.id || null,
        dosage: _medicine?.dosage || "",
        medicine: _medicine?.medicine || "",
        description: _medicine?.description || "",
      });
    },
    refetchQueries: !_medicine
      ? () => [
          {
            query: GetMyMedicinesDocument,
          },
        ]
      : undefined,
  });
  const [deleteMyMedicine] = useDeleteMyMedicineMutation({
    refetchQueries: () => [
      {
        query: GetMyMedicinesDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onMedicineSearched(text: string) {
    setState((prev) => ({
      ...prev,
      medicine: text,
    }));
  }

  function onDeleteClick() {
    deleteMyMedicine({
      variables: {
        userMedicineId: _medicine?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { dosage, medicine, description } = state;

    return !dosage || !medicine || !description;
  }

  function onAddClick() {
    if (isSubmitDisabled()) return;

    const { dosage, medicine, description } = state;

    updateMyMedicine({
      variables: {
        userMedicineInput: {
          id: _medicine?.id,
          dosage,
          medicine,
          description,
        },
      },
    });
  }

  function toggleModal() {
    setShowModal(!showModal);
  }

  function closeModal() {
    setShowModal(false);
  }

  return {
    models: {
      state,
      showModal,
    },
    operations: {
      onInputChange,
      onDeleteClick,
      onAddClick,
      toggleModal,
      onMedicineSearched,
      closeModal,
    },
  };
}

export default useMedicineForm;
