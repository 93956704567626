import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  UserPassStatus,
  useGetManagementQuery,
} from "../../../../lib/apollo/graphql/generated";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import Badge from "../../../common/components/Badge";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Text from "../../../common/components/Text";
import { getMinutesFromSeconds } from "../../../common/utils/getMinutesFromSeconds";
import { getNumbersWithCommas } from "../../../common/utils/getNumbersWithCommas";
import Modal from "../../../common/components/Modal";
import SendPassModal from "./SendPassModal";

interface MyPassCardProps {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  price: number | string;
  usuableTickets: number;
  isActive: boolean;
  isMyPurchased?: boolean;
}

function MyPassCard({
  id,
  name,
  createdAt,
  updatedAt,
  price,
  usuableTickets,
  isActive,
  isMyPurchased,
}: MyPassCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useGetManagementQuery();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Flex
        flexDirection="column"
        width="100%"
        borderRadius={theme.spacing[8]}
        padding={theme.spacing[16]}
        border={`1px solid ${theme.color.neutral800}`}
      >
        <Text typography="heading3" color={theme.color.neutral700}>
          {name}
        </Text>
        <Text typography="body1Bold" color={theme.color.neutral500}>
          {dayjs(isMyPurchased ? createdAt : updatedAt).format("YYYY.MM.DD")}
        </Text>
        <Text typography="body2" color={theme.color.neutral500}>
          {getNumbersWithCommas(price)}
          {t("common.currencyUnitText")}
        </Text>
        <Badge
          fontColor="neutral900"
          round={false}
          backgroundColor="primary4"
          size="sm"
          text={
            t("myPage.availableTime") +
            " " +
            (data?.getManagement?.sessionDuration || 0) +
            t("common.minute")
          }
        />
        {isActive && (
          <Flex
            alignItems="center"
            marginTop={theme.spacing[12]}
            gap={theme.spacing[16]}
          >
            <Button
              round={false}
              text={t("myPage.viewInstructors")}
              size="md"
              onClick={() => navigate(SCREENS.HOME)}
            />
            <Button
              round={false}
              text={t("myPage.send")}
              size="md"
              onClick={() => setShowModal(true)}
            />
          </Flex>
        )}
      </Flex>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onRequestClose={() => setShowModal(false)}
        >
          <SendPassModal passId={id} onClose={() => setShowModal(false)} />
        </Modal>
      )}
    </>
  );
}

export default MyPassCard;
