import React from "react";
import Flex from "../../../common/components/Flex";
import Text from "../../../common/components/Text";
import { useTranslation } from "react-i18next";
import theme from "../../../../lib/theme";

function PHRMainTitle() {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      padding={theme.spacing[8]}
      backgroundColor={theme.color.tertiary3}
    >
      <Text typography="heading4" color={theme.color.primary1}>
        {t("phr.mainTitle")}
      </Text>
    </Flex>
  );
}

export default PHRMainTitle;
