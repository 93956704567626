import { useTranslation } from "react-i18next";
import {
  GetMyAllergiesDocument,
  useDeleteMyAllergyMutation,
  useGetAllergySymptomsQuery,
  useGetAllergyTypesQuery,
  useUpdateMyAllergyMutation,
} from "../../../../lib/apollo/graphql/generated";
import { useState } from "react";
import { AllergyFormProps } from "./AllergyForm";
import { toast } from "react-toastify";

function useAllergyForm({ allergy }: AllergyFormProps) {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    id: allergy?.id || null,
    typeId: allergy?.type?.id || null,
    symptomIds:
      !!allergy?.symptoms && allergy?.symptoms?.length > 0
        ? allergy?.symptoms?.map((symptom) => symptom?.id)
        : [],
    summary: allergy?.summary || "",
    firstAid: allergy?.firstAid || "",
  });

  const { loading, data } = useGetAllergyTypesQuery({
    variables: {
      language: i18n.resolvedLanguage || "ko",
      activeOnly: true,
    },
  });

  const { data: symptomsData } = useGetAllergySymptomsQuery({
    variables: {
      language: i18n.resolvedLanguage || "ko",
      activeOnly: true,
    },
  });

  const [updateMyAllergy] = useUpdateMyAllergyMutation({
    onCompleted: (data) => {
      setState({
        id: allergy?.id || null,
        typeId: allergy?.type?.id || null,
        symptomIds:
          !!allergy?.symptoms && allergy?.symptoms?.length > 0
            ? allergy?.symptoms?.map((symptom) => symptom?.id)
            : [],
        summary: allergy?.summary || "",
        firstAid: allergy?.firstAid || "",
      });
    },
    refetchQueries: !allergy
      ? () => [
          {
            query: GetMyAllergiesDocument,
          },
        ]
      : undefined,
  });
  const [deleteMyAllergy] = useDeleteMyAllergyMutation({
    refetchQueries: () => [
      {
        query: GetMyAllergiesDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onTypeChange(id: string) {
    setState((prev) => ({
      ...prev,
      typeId: id,
    }));
  }

  function onSymptomClick(id: string) {
    let editedSymptomIds = [...state?.symptomIds];

    if (editedSymptomIds?.includes(id)) {
      editedSymptomIds = editedSymptomIds.filter(
        (symptomId) => symptomId !== id
      );
    } else {
      editedSymptomIds.push(id);
    }

    setState((prev) => ({
      ...prev,
      symptomIds: editedSymptomIds,
    }));
  }

  function onDeleteClick() {
    deleteMyAllergy({
      variables: {
        userAllergyId: allergy?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { typeId, summary, firstAid } = state;

    return !typeId || !firstAid;
  }

  function onAddClick() {
    if (isSubmitDisabled()) return;

    const { typeId, symptomIds, summary, firstAid } = state;

    updateMyAllergy({
      variables: {
        userAllergyInput: {
          id: allergy?.id,
          typeId: typeId!,
          symptomIds: symptomIds as string[],
          summary,
          firstAid,
        },
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getAllergyTypes,
      symptoms: symptomsData?.getAllergySymptoms,
      state,
    },
    operations: {
      onInputChange,
      onTypeChange,
      onSymptomClick,
      onDeleteClick,
      onAddClick,
    },
  };
}

export default useAllergyForm;
