import { useGetMySurgerysQuery } from "../../../../lib/apollo/graphql/generated";

function useSurgeryContainer() {
  const { loading, data } = useGetMySurgerysQuery();

  return {
    models: {
      loading,
      data: data?.getMySurgerys,
    },
  };
}

export default useSurgeryContainer;
