import React, { useState } from "react";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import Input from "../../../common/components/Input";
import { PHONE_CODE } from "../../../../utils/constants";
import { SingleValue } from "react-select";
import PhoneInput from "../../../common/components/PhoneInput";
import Button from "../../../common/components/Button";
import { useSendUserPassMutation } from "../../../../lib/apollo/graphql/generated";
import { toast } from "react-toastify";

interface SendPassModalProps {
  passId: string;
  onClose: () => void;
}

function SendPassModal({ passId, onClose }: SendPassModalProps) {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const [state, setState] = useState({
    name: "",
    phone: "",
    phoneCode: {
      label: PHONE_CODE[0].country + `(${PHONE_CODE[0].code})`,
      value: PHONE_CODE[0].code,
    },
  });

  const [sendUserPass, { loading }] = useSendUserPassMutation({
    onCompleted: (data) => {
      if (data?.sendUserPass) {
        toast(`${state?.name}님에게 이용건이 전송되었습니다`);

        onClose();
      }
    },
    onError: () => {
      toast("전화번호를 다시 확인해주세요");
    },
  });

  function onPhoneCodeChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      phoneCode: newValue!,
    }));
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: key === "phone" ? value.replace("-", "") : value,
    }));
  }

  function onSubmit() {
    sendUserPass({
      variables: {
        userPassId: passId,
        name: state?.name,
        phone: state?.phone,
      },
    });
  }

  const { name, phone, phoneCode } = state;

  return (
    <Flex flexDirection="column" alignItems="center" gap={theme.spacing[16]}>
      <Text typography={!isMobile ? "heading3" : "heading5"} textAlign="center">
        {t("userPass.sendTitle")}
      </Text>

      <Text
        typography={isMobile ? "body3Bold" : "body2Bold"}
        padding={`${theme.spacing[16]}px ${theme.spacing[4]}px`}
        backgroundColor="#f5f5f5"
        width="100%"
      >
        {t("userPass.sendDescription")}
      </Text>

      <Input
        label={t("userPass.name")}
        placeholder={t("userPass.namePlaceholder")}
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "name")
        }
      />
      <PhoneInput
        phoneCode={phoneCode}
        label={t("common.phone")}
        inputMode="tel"
        placeholder={t("common.phonePlaceholder")}
        value={phone}
        maxLength={11}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "phone")
        }
        onPhoneCodeChange={onPhoneCodeChange}
      />
      <Button
        disabled={!phone || !phoneCode || !phone}
        text={loading ? "전송중" : "보내기"}
        size={isMobile ? "md" : "lg"}
        onClick={onSubmit}
      />
    </Flex>
  );
}

export default SendPassModal;
