import React from "react";
import Button from "../../../common/components/Button";
import addCircle from "../../../../assets/icon/add_circle.png";
import { useTranslation } from "react-i18next";

interface PHRAddButtonProps {
  text: string;
  onClick?: () => void;
}

function PHRAddButton({ text, onClick }: PHRAddButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      size="lg"
      leftIcon={addCircle}
      text={t("phr." + text) + " " + t("common.add")}
      fullWidth
      variant="outline"
      bgColor="neutralWhite"
      fontColor="primary1"
      onClick={onClick}
    />
  );
}

export default PHRAddButton;
