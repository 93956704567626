import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";

interface LabelProps {
  text?: string;
  current?: boolean;
  size?: "lg" | "md" | "sm";
  disabled?: boolean;
  onClick?: () => void;
}

function Label({
  text,
  current = false,
  size = "md",
  disabled = false,
  onClick,
}: LabelProps) {
  const padding =
    size === "lg"
      ? `${theme.spacing[10]}px ${theme.spacing[24]}px`
      : size === "md"
      ? `${theme.spacing[8]}px ${theme.spacing[24]}px`
      : `${theme.spacing[4]}px ${theme.spacing[16]}px`;

  const font =
    size === "lg"
      ? current
        ? theme.typography.heading4
        : theme.typography.body1
      : size === "md"
      ? theme.typography.body2
      : theme.typography.body3;

  const borderBottom = current ? `2px solid ${theme.color.neutral800}` : "none";
  const fontWeight = current ? 600 : "normal";

  return (
    <Flex
      width="fit-content"
      padding={padding}
      {...font}
      borderBottom={borderBottom}
      fontWeight={fontWeight}
      cursor={disabled ? "auto" : "pointer"}
      color={disabled ? theme.color.neutral700 : theme.color.neutral900}
      textAlign="center"
      onClick={onClick}
    >
      {text}
    </Flex>
  );
}

export default Label;
