import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import Flex from "./Flex";
import IconButton from "./IconButton";
import Text from "./Text";
import checkedIcon from "../../../assets/icon/checkbox_checked.png";
import uncheckedIcon from "../../../assets/icon/checkbox_unchecked.png";
import theme from "../../../lib/theme";

interface BillingCardProps {
  cardName?: string | null;
  cardNumber?: string | null;
  isActive?: boolean;
  onClick?: () => void;
  onDeleteClick?: () => void;
}

function BillingCard({
  cardName,
  cardNumber,
  isActive,
  onClick,
  onDeleteClick,
}: BillingCardProps) {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      border={`1px solid ${theme.color.neutral800}`}
      borderRadius={8}
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
    >
      <Text typography="heading3" color={theme.color.neutral700}>
        {cardName}
      </Text>
      <Text typography="heading3" color={theme.color.neutral600}>
        {cardNumber}
      </Text>
      <Flex
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing[12]}
        marginBottom={theme.spacing[12]}
      >
        <IconButton
          fullWidth
          size="md"
          icon={isActive ? checkedIcon : uncheckedIcon}
          onClick={onClick}
        />
        <Text typography="body1Bold" color={theme.color.primary2}>
          {t("common.activePayMethod")}
        </Text>
      </Flex>
      {onDeleteClick && (
        <Button
          disabled={isActive}
          text={t("common.delete")}
          round={false}
          size="lg"
          onClick={onDeleteClick}
        />
      )}
    </Flex>
  );
}

export default BillingCard;
