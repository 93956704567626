import React from "react";
import Section from "../../../common/components/Section";
import theme from "../../../../lib/theme";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import MyPageHeader from "../../common/components/MyPageHeader";
import { useTranslation } from "react-i18next";
import Divider from "../../../common/components/Divider";
import useMyBillingContainer from "./MyBillingContainer.hook";
import Loading from "../../../common/components/Loading";
import Text from "../../../common/components/Text";
import Button from "../../../common/components/Button";
import MyBillingCard from "../components/MyBillingCard";
import Spacer from "../../../common/components/Spacer";
import Flex from "../../../common/components/Flex";
import addIcon from "../../../../assets/icon/add_white.png";
import useAddBilling from "../../../common/components/AddBilling.hook";

function MyBillingContainer() {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const {
    models: { loading, data },
  } = useMyBillingContainer();

  const {
    operations: { onAddClick },
  } = useAddBilling();

  return (
    <Section
      innerStyle={{
        width: "100%",
        maxWidth: 794,
        paddingTop: theme.spacing[isMobile ? 16 : 48],
        paddingBottom: theme.spacing[isMobile ? 16 : 48],
      }}
    >
      <MyPageHeader title={t("myPage.billingTitle")} />

      <Divider full size={1} />

      <Spacer size={24} />

      {loading ? (
        <Loading />
      ) : !!data && data?.length > 0 ? (
        <Flex
          width="100%"
          gap={theme.spacing[24]}
          flexDirection="column"
          marginBottom={theme.spacing[24]}
        >
          {data?.map((billing) => (
            <MyBillingCard key={billing?.id} billing={billing!} />
          ))}
        </Flex>
      ) : (
        <>
          <Text typography="heading4" marginBottom={theme.spacing[24]}>
            {t("myPage.billingNone")}
          </Text>
        </>
      )}
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Button
          leftIcon={addIcon}
          text={t("myPage.addBilling")}
          size="lg"
          round={false}
          onClick={onAddClick}
        />
      </Flex>
    </Section>
  );
}

export default MyBillingContainer;
