import React from "react";
import { useTranslation } from "react-i18next";
import useMyPassesContainer from "./MyPassesContainer.hook";
import { UserPassStatus } from "../../../../lib/apollo/graphql/generated";
import theme from "../../../../lib/theme";
import { USER_PASS_TAB } from "../../../../utils/constants";
import Divider from "../../../common/components/Divider";
import FetchMore from "../../../common/components/FetchMore";
import Flex from "../../../common/components/Flex";
import Loading from "../../../common/components/Loading";
import Section from "../../../common/components/Section";
import Tabs from "../../../common/components/Tabs";
import { useAuthContext } from "../../../common/provider/AuthProvider";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import MyPageHeader from "../../common/components/MyPageHeader";
import MyPassCard from "../components/MyPassCard";
import MyPassCodeInput from "../components/MyPassCodeInput";
import Text from "../../../common/components/Text";
import Button from "../../../common/components/Button";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";

function MyPassesContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const { profile } = useAuthContext();

  const {
    refs: { fetchMoreRef },
    models: { loading, data, tab },
    operations: { onTabChange },
  } = useMyPassesContainer();

  return (
    <Section
      innerStyle={{
        width: "100%",
        maxWidth: 794,
        paddingTop: theme.spacing[isMobile ? 16 : 48],
        paddingBottom: theme.spacing[isMobile ? 16 : 48],
      }}
    >
      <MyPageHeader title={t("myPage.passHistoryTitle")} />
      <Divider full size={1} />
      <MyPassCodeInput />
      <Tabs
        tabs={[
          {
            text: t("myPage.availableTicket"),
            selected: tab === USER_PASS_TAB.AVAILABLE,
            onClick: () => onTabChange(USER_PASS_TAB.AVAILABLE),
          },
          {
            text: t("myPage.usedTicket"),
            selected: tab === USER_PASS_TAB.USED,
            onClick: () => onTabChange(USER_PASS_TAB.USED),
          },
        ]}
        size={!isMobile ? "lg" : "sm"}
      />
      {loading ? (
        <Loading />
      ) : (
        <Flex
          width="100%"
          flexDirection="column"
          gap={theme.spacing[24]}
          marginTop={theme.spacing[24]}
        >
          {!!data && data?.length > 0 ? (
            data?.map((pass) => (
              <MyPassCard
                key={pass?.id}
                id={pass?.id!}
                name={pass?.pass?.name!}
                price={pass?.pass?.finalPrice!}
                createdAt={pass?.createdAt}
                updatedAt={pass?.updatedAt}
                usuableTickets={pass?.pass?.tickets!}
                isMyPurchased={
                  pass?.user?.id === pass?.purchaser?.id &&
                  pass?.purchaser?.id === profile?.id
                }
                isActive={
                  pass?.status === UserPassStatus.Received ||
                  pass?.status === UserPassStatus.Purchased
                }
              />
            ))
          ) : (
            <>
              <Text typography="heading4" marginBottom={theme.spacing[24]}>
                {t("myPage.codeNone")}
              </Text>
              <Button
                size="lg"
                text={t("myPage.goToSubscribe")}
                onClick={() => navigate(SCREENS.PURCHASE + SCREENS.ITEMS)}
              />
            </>
          )}
          <FetchMore fetchMoreRef={fetchMoreRef} />
        </Flex>
      )}
    </Section>
  );
}

export default MyPassesContainer;
