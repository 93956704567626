import { useGetMyVaccinesQuery } from "../../../../lib/apollo/graphql/generated";

function useVaccineContainer() {
  const { loading, data } = useGetMyVaccinesQuery();

  return {
    models: {
      loading,
      data: data?.getMyVaccines,
    },
  };
}

export default useVaccineContainer;
