import React, { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { SCREENS } from "../../../lib/route/utils/router";
import { COMPRESSIONS_OPTIONS, PHONE_CODE } from "../../../utils/constants";
import {
  BloodType,
  GetMyProfileDocument,
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
} from "../../../lib/apollo/graphql/generated";

export interface AdditionalProfileState {
  id: string | null;
  weight: string;
  height: string;
  bloodType: BloodType | null;
  occupation: string;
  companyName: string;
  emergencyCallerName: string;
  emergencyCallerNationality: null | { label: string; value: string };
  emergencyCallerPhoneCode: { label: string; value: string };
  emergencyCallerPhone: string;
  emergencyCallerBloodType: BloodType | null;
  emergencyCallerRelation: string;
  emergencyCallerAddress: string;
}

const defaultState: AdditionalProfileState = {
  id: null,
  weight: "",
  height: "",
  bloodType: null,
  occupation: "",
  companyName: "",
  emergencyCallerName: "",
  emergencyCallerNationality: null,
  emergencyCallerPhoneCode: {
    label: PHONE_CODE[0].country + `(${PHONE_CODE[0].code})`,
    value: PHONE_CODE[0].code,
  },
  emergencyCallerPhone: "",
  emergencyCallerBloodType: null,
  emergencyCallerRelation: "",
  emergencyCallerAddress: "",
};

function useAdditionalProfileForm(isCreating: boolean) {
  const navigate = useNavigate();

  const [state, setState] = useState({ ...defaultState });

  const { loading, data, refetch } = useGetMyProfileQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.getMyProfile) {
        const {
          id,
          weight,
          height,
          bloodType,
          occupation,
          companyName,
          emergencyCallerName,
          emergencyCallerNationality,
          emergencyCallerPhoneCode,
          emergencyCallerPhone,
          emergencyCallerBloodType,
          emergencyCallerRelation,
          emergencyCallerAddress,
        } = data?.getMyProfile;

        let emergencyPhoneCode = {
          label: PHONE_CODE[0].country + `(${PHONE_CODE[0].code})`,
          value: PHONE_CODE[0].code,
        };

        if (emergencyCallerPhoneCode) {
          const currentPhoneCode = PHONE_CODE.find(
            (code) => code.code === emergencyCallerPhoneCode
          );
          if (currentPhoneCode) {
            emergencyPhoneCode = {
              label: currentPhoneCode!.country + `(${currentPhoneCode!.code})`,
              value: currentPhoneCode!.code,
            };
          }
        }

        setState((prev) => ({
          ...prev,
          id,
          weight: weight ? weight.toString() : "",
          height: height ? height.toString() : "",
          bloodType: bloodType || null,
          occupation: occupation || "",
          companyName: companyName || "",
          emergencyCallerNationality: emergencyCallerNationality
            ? {
                label: emergencyCallerNationality?.name,
                value: emergencyCallerNationality?.id,
              }
            : null,
          emergencyCallerName: emergencyCallerName || "",
          emergencyCallerPhoneCode: emergencyPhoneCode,
          emergencyCallerPhone: emergencyCallerPhone || "",
          emergencyCallerBloodType: emergencyCallerBloodType || null,
          emergencyCallerRelation: emergencyCallerRelation || "",
          emergencyCallerAddress: emergencyCallerAddress || "",
        }));

        if (isCreating) {
          window.location.reload();
        }
      }
    },
  });

  const [updateMyProfile, { loading: updateLoading }] =
    useUpdateMyProfileMutation({
      onCompleted: (data) => {
        if (data?.updateMyProfile) {
          if (isCreating) {
            // navigate(SCREENS.PHR);
          }
        }
      },
      refetchQueries: isCreating
        ? () => [
            {
              query: GetMyProfileDocument,
            },
          ]
        : undefined,
    });

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    if (key === "weight" || key === "height") {
      if (isNaN(Number(value))) {
        return;
      }
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "phone" ? value.replace("-", "") : value,
    }));
  }

  function onBloodTypeChange(type: BloodType, key: string) {
    setState((prev) => ({
      ...prev,
      [key]: type,
    }));
  }

  function onNationalityChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      emergencyCallerNationality: newValue,
    }));
  }

  function onPhoneCodeChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      emergencyCallerPhoneCode: newValue!,
    }));
  }

  async function onSubmit() {
    const {
      id,
      weight,
      height,
      bloodType,
      occupation,
      companyName,
      emergencyCallerName,
      emergencyCallerNationality,
      emergencyCallerPhoneCode,
      emergencyCallerPhone,
      emergencyCallerBloodType,
      emergencyCallerRelation,
      emergencyCallerAddress,
    } = state;

    updateMyProfile({
      variables: {
        profileInput: {
          id,
          weight: !!weight ? Number(weight) : null,
          height: !!height ? Number(height) : null,
          bloodType,
          occupation,
          companyName,
          emergencyCallerName,
          emergencyCallerNationalityId: emergencyCallerNationality?.value,
          emergencyCallerPhoneCode: emergencyCallerPhoneCode?.value,
          emergencyCallerPhone,
          emergencyCallerBloodType,
          emergencyCallerRelation,
          emergencyCallerAddress,
        },
      },
    });
  }

  return {
    models: {
      state,
      loading,
      updateLoading,
    },
    operations: {
      onInputChange,
      onNationalityChange,
      onPhoneCodeChange,
      onBloodTypeChange,
      onSubmit,
    },
  };
}

export default useAdditionalProfileForm;
