import React from "react";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";
import Text from "../../../common/components/Text";
import checkedIcon from "../../../../assets/icon/checkbox_checked.png";
import uncheckedIcon from "../../../../assets/icon/checkbox_unchecked.png";
import Button from "../../../common/components/Button";
import {
  SubscriptionStatus,
  useChangeActiveBillingMutation,
  useChangeBillingKeyForSubscriptionMutation,
  useGetMySubscriptionsQuery,
} from "../../../../lib/apollo/graphql/generated";

interface MyBillingCardProps {
  billing: {
    __typename?: "Billing";
    id: string;
    cardName?: string | null;
    cardNumber?: string | null;
    pgProvider?: string | null;
    customerUid: string;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  };
}

function MyBillingCard({ billing }: MyBillingCardProps) {
  const { t } = useTranslation();

  const { data: subscriptionData, loading } = useGetMySubscriptionsQuery();

  const { cardName, cardNumber, active } = billing;

  const [changeActiveBilling] = useChangeActiveBillingMutation({
    onCompleted: (data) => {
      if (data?.changeActiveBilling?.active) {
        const activeSubscription = subscriptionData?.getMySubscriptions?.find(
          (subscription) => subscription?.status === SubscriptionStatus.Active
        );

        if (activeSubscription) {
          changeBillingKeyForSubscription({
            variables: {
              subscriptionId: activeSubscription.id,
              newBillingId: data?.changeActiveBilling?.id,
            },
          });
        }
      }
    },
  });
  const [changeBillingKeyForSubscription] =
    useChangeBillingKeyForSubscriptionMutation();

  function onActiveClick() {
    if (!active) {
      changeActiveBilling({
        variables: {
          billingId: billing.id,
        },
      });
    }
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
      border={`1px solid ${theme.color.neutral800}`}
      backgroundColor="white"
      borderRadius={8}
    >
      <Text typography="heading3" color={theme.color.neutral800}>
        {cardName}
      </Text>
      <Text typography="body2" color={theme.color.neutral700}>
        {cardNumber}
      </Text>
      <Flex
        alignItems="center"
        gap={theme.spacing[12]}
        marginBottom={theme.spacing[12]}
      >
        <img
          src={active ? checkedIcon : uncheckedIcon}
          style={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={onActiveClick}
        />
        <Text typography="body1Bold" color={theme.color.primary2}>
          {t("myBilling.primary")}
        </Text>
      </Flex>
    </Flex>
  );
}

export default MyBillingCard;
