import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../../lib/theme";
import Button from "../../../common/components/Button";
import Flex from "../../../common/components/Flex";
import Input from "../../../common/components/Input";
import Section from "../../../common/components/Section";
import Text from "../../../common/components/Text";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import {
  GetMyOwnUserPassesDocument,
  UserPassStatus,
  useReceiveUserPassMutation,
} from "../../../../lib/apollo/graphql/generated";
import { toast } from "react-toastify";

function MyPassCodeInput() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const [code, setCode] = useState("");

  const [receiveUserPass, { loading }] = useReceiveUserPassMutation({
    onCompleted: (data) => {
      if (data?.receiveUserPass) {
        setCode("");
      } else {
        toast(t("myPage.notValidCode"));
      }
    },
    refetchQueries: () => [
      {
        query: GetMyOwnUserPassesDocument,
        variables: {
          status: [UserPassStatus.Purchased, UserPassStatus.Received],
          first: 4,
        },
      },
    ],
  });

  function onCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setCode(value.trim());
  }

  function onSubmit() {
    if (!code?.length) {
      return;
    }

    receiveUserPass({
      variables: {
        code,
      },
    });
  }

  const disabled = loading || !code?.length;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      paddingTop={theme.spacing[24]}
      paddingBottom={theme.spacing[24]}
    >
      <Text
        typography="heading5"
        color={theme.color.neutral800}
        marginBottom={theme.spacing[8]}
      >
        {t("myPage.passCodeTitle")}
      </Text>
      <div style={{ marginBottom: theme.spacing[12] }}>
        <Input
          placeholder={t("myPage.passCodePlaceholder")}
          value={code}
          onChange={onCodeChange}
        />
      </div>
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Button
          disabled={disabled}
          text={t("common.register")}
          round={false}
          variant="outline"
          bgColor={disabled ? "neutral300" : "tertiary1"}
          borderColor="neutral900"
          fontColor="neutral900"
          onClick={onSubmit}
        />
      </Flex>
    </Flex>
  );
}

export default MyPassCodeInput;
