import React from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";

export interface BadgeProps {
  size?: "lg" | "sm" | "xs";
  text?: string;
  round?: boolean;
  backgroundColor?: keyof Color;
  fontColor?: keyof Color;
  isBold?: boolean;
}

function Badge({
  size = "sm",
  text,
  round = false,
  backgroundColor = "neutral700",
  fontColor = "neutralWhite",
  isBold = false,
}: BadgeProps) {
  const borderRadius = round ? 30 : 4;

  const font =
    size === "lg"
      ? theme.typography.body1Bold
      : size === "sm"
      ? theme.typography.body2Bold
      : theme.typography.body3;

  const fontWeight = isBold ? 800 : "normal";

  const padding = size === "xs" ? "2px 4px" : "2px 8px";

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      {...font}
      fontWeight={fontWeight}
      borderRadius={borderRadius}
      color={theme.color[fontColor]}
      backgroundColor={theme.color[backgroundColor]}
      padding={padding}
      width="fit-content"
    >
      {text}
    </Flex>
  );
}

export default Badge;
