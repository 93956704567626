import React from "react";
import Layout from "../../common/components/Layout";
import Section from "../../common/components/Section";
import useInstructorContainer from "./InstructorContainer.hook";
import Loading from "../../common/components/Loading";
import Text from "../../common/components/Text";
import { useTranslation } from "react-i18next";
import theme from "../../../lib/theme";
import InstructorThumbnail from "../../common/components/InstructorThumbnail";
import Flex from "../../common/components/Flex";
import Grid from "../../common/components/Grid";
import Divider from "../../common/components/Divider";
import circleAdd from "../../../assets/icon/circle_add_white.png";
import circleMinus from "../../../assets/icon/circle_minus_blue.png";
import Button from "../../common/components/Button";

function InstructorContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data, showMore },
    operations: { toggleShowMore },
  } = useInstructorContainer();

  if (loading) return <Loading />;

  const {
    id,
    user: { name, avatar },
    educations,
    introduction,
    experiences,
    job,
    online,
  } = data!;

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 670,
        }}
      >
        <Text
          typography="heading2"
          color={theme.color.neutral800}
          marginBottom={theme.spacing[24]}
        >
          {name} {t("instructor.title")}
        </Text>
        <Grid
          width="100%"
          gridTemplateColumns="1fr"
          marginBottom={theme.spacing[24]}
        >
          <InstructorThumbnail
            id={id}
            avatar={avatar?.uri}
            name={name!}
            job={job}
            lastWorkAt={experiences?.[0]?.place}
            showOnline={false}
            online={data?.online}
            isDetail
          />
        </Grid>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          borderBottom={`1px solid ${theme.color.neutral900}`}
          padding={`${theme.spacing[16]}px 0px`}
          marginBottom={theme.spacing[24]}
        >
          <Text typography="heading3" color={theme.color.neutral800}>
            {name}
            {t("instructor.mainProfileTitle")}
          </Text>
          <Text typography="label2" color={theme.color.neutral600}>
            {introduction}
          </Text>
        </Flex>

        <Flex width="100%" flexDirection="column" gap={theme.spacing[16]}>
          <Text
            typography="heading3"
            color={theme.color.neutral800}
            width="100%"
            textAlign="center"
          >
            {t("instructor.profileTitle")}
          </Text>

          <Flex
            flexDirection="column"
            gap={theme.spacing[16]}
            padding={`${theme.spacing[16]}px 0px`}
          >
            <Text typography="heading4" color={theme.color.neutral800}>
              {t("instructor.qualification")}
            </Text>
            <Divider size={1} full />
            <Text typography="body1" color={theme.color.neutral800}>
              {t("common." + job)}
            </Text>
          </Flex>

          <Flex width="100%" alignItems="center" justifyContent="center">
            <Button
              text={t("instructor.viewMore")}
              size="lg"
              variant={!showMore ? "contained" : "outline"}
              bgColor={showMore ? "neutralWhite" : "primary1"}
              fontColor={!showMore ? "neutralWhite" : "primary1"}
              rightIcon={showMore ? circleMinus : circleAdd}
              onClick={toggleShowMore}
            />
          </Flex>

          {showMore && (
            <>
              {!!experiences && experiences?.length > 0 && (
                <Flex
                  width="100%"
                  flexDirection="column"
                  marginBottom={theme.spacing[32]}
                  gap={theme.spacing[16]}
                  padding={`${theme.spacing[16]}px 0px`}
                >
                  <Text typography="heading4" color={theme.color.neutral800}>
                    {t("instructor.experienceTitle")}
                  </Text>
                  <Divider size={1} full />
                  {experiences?.map((experience) => (
                    <Flex
                      key={experience?.id}
                      flexDirection="column"
                      width="100%"
                      gap={theme.spacing[16]}
                    >
                      <Text typography="heading4" color={theme.color.primary1}>
                        {t("instructor.experiencePlace")}
                      </Text>
                      <Text typography="body1" color={theme.color.neutral800}>
                        {experience?.place}
                      </Text>
                      <Text typography="heading4" color={theme.color.primary1}>
                        {t("instructor.experienceJob")}
                      </Text>
                      <Text typography="body1" color={theme.color.neutral800}>
                        {experience?.job}
                      </Text>
                      <Text typography="heading4" color={theme.color.primary1}>
                        {t("instructor.experienceDuration")}
                      </Text>
                      <Text typography="body1" color={theme.color.neutral800}>
                        {experience?.duration} {t("common.year")}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              )}
              {!!educations && educations?.length > 0 && (
                <Flex
                  width="100%"
                  flexDirection="column"
                  marginBottom={theme.spacing[32]}
                  gap={theme.spacing[16]}
                  padding={`${theme.spacing[16]}px 0px`}
                >
                  <Text typography="heading4" color={theme.color.neutral800}>
                    {t("instructor.educationTitle")}
                  </Text>
                  <Divider size={1} full />
                  {educations?.map((education) => (
                    <Flex
                      key={education?.id}
                      flexDirection="column"
                      width="100%"
                      gap={theme.spacing[16]}
                    >
                      <Text typography="heading4" color={theme.color.primary1}>
                        {t("instructor.educationSchool")}
                      </Text>
                      <Text typography="body1" color={theme.color.neutral800}>
                        {education?.school}
                      </Text>
                      <Text typography="heading4" color={theme.color.primary1}>
                        {t("instructor.educationMajor")}
                      </Text>
                      <Text typography="body1" color={theme.color.neutral800}>
                        {education?.major}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              )}
              {!!introduction && (
                <Flex
                  width="100%"
                  flexDirection="column"
                  marginBottom={theme.spacing[32]}
                  gap={theme.spacing[16]}
                  padding={`${theme.spacing[16]}px 0px`}
                >
                  <Text typography="heading4" color={theme.color.neutral800}>
                    {t("instructor.introductionTitle")}
                  </Text>
                  <Divider size={1} full />
                  <Text typography="heading4" color={theme.color.primary1}>
                    {t("instructor.introductionSubtitle")}
                  </Text>
                  <Text typography="body1" color={theme.color.neutral800}>
                    {introduction}
                  </Text>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Section>
    </Layout>
  );
}

export default InstructorContainer;
