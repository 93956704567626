import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";
import Button from "./Button";
import Flex from "./Flex";
import InvitationModal from "./InvitationModal";
import Modal from "./Modal";
import Text from "./Text";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";

interface InstructorThumbnailProps {
  id: string;
  avatar?: string | null;
  name: string;
  job?: string | null;
  lastWorkAt?: string | null;
  showOnline?: boolean;
  online?: boolean;
  isDetail?: boolean;
}

function InstructorThumbnail({
  id,
  avatar,
  name,
  job,
  lastWorkAt,
  showOnline = true,
  online,
  isDetail,
}: InstructorThumbnailProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        borderRadius={8}
        paddingTop={theme.spacing[24]}
        paddingBottom={theme.spacing[24]}
        gap={theme.spacing[24]}
        backgroundColor={
          isDetail ? theme.color.neutral200 : theme.color.neutralWhite
        }
        border={`1px solid ${theme.color.neutral800}`}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing[4]}
          padding={theme.spacing[16]}
        >
          <Avatar
            uri={avatar}
            initial={name?.slice(0, 1)}
            size={120}
            showOnline={showOnline}
            online={online}
          />
          <Text typography="heading3" color={theme.color.neutral700}>
            {name}
          </Text>
          {(job || lastWorkAt) && (
            <Text typography="body1Bold" color={theme.color.neutral300}>
              {job ? t("common." + job) : ""}
              {job ? ", " : ""}
              {lastWorkAt || ""}
            </Text>
          )}
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[20]}
        >
          {!isDetail && (
            <Button
              round={false}
              text={t("common.viewProfile")}
              size={isMobile ? "sm" : "lg"}
              onClick={() => navigate(SCREENS.INSTRUCTOR + "/" + id)}
            />
          )}
          {online && (
            <Button
              round={false}
              text={t("common.request")}
              size={isMobile ? "sm" : "lg"}
              variant="outline"
              borderColor="neutral900"
              bgColor="tertiary1"
              fontColor="neutral900"
              onClick={() => setShowModal(!showModal)}
            />
          )}
        </Flex>
      </Flex>

      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onRequestClose={() => setShowModal(false)}
        >
          <InvitationModal
            instructorId={id}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}
    </>
  );
}

export default InstructorThumbnail;
