import React from "react";
import Flex from "../../../common/components/Flex";
import PHRPathname from "../../phr/component/PHRPathname";
import PHRAddButton from "../../phr/component/PHRAddButton";
import useMedicineContainer from "./MedicineContainer.hook";
import Loading from "../../../common/components/Loading";
import MedicineForm from "../components/MedicineForm";
import Button from "../../../common/components/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function MedicineContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data },
  } = useMedicineContainer();

  if (loading) return <Loading />;

  return (
    <>
      <PHRPathname name="medicineTitle" />
      <MedicineForm medicine={null} />
      {data?.map((medicine) => (
        <MedicineForm key={medicine?.id} medicine={medicine!} />
      ))}
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Button
          text={t("common.save")}
          size="lg"
          round={false}
          onClick={() => toast(t("common.saved"))}
        />
      </Flex>
    </>
  );
}

export default MedicineContainer;
