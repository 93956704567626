import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetPassesByLanguageQuery } from "../../../../lib/apollo/graphql/generated";
import { SCREENS } from "../../../../lib/route/utils/router";
import { PURCHASE_TYPE } from "../../../../utils/constants";

interface State {
  type: string | null;
  id: string | null;
}

function usePurchaseItemsContainer() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState<State>({
    type: null,
    id: null,
  });

  const { loading, data } = useGetPassesByLanguageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      language: i18n.resolvedLanguage!,
    },
  });

  function onItemSelect(type: string, id: string) {
    setSelectedItem({ type, id });
  }

  function onSubmit() {
    const { type, id } = selectedItem;

    if (!id) return;

    if (type === PURCHASE_TYPE.SUBSCRIPTION) {
      navigate(SCREENS.PURCHASE + SCREENS.SUBSCRIPTION + "/" + id);
    } else {
      navigate(SCREENS.PURCHASE + SCREENS.PASS + "/" + id);
    }
  }

  return {
    models: {
      loading,
      data,
      selectedItem,
    },
    operations: {
      onItemSelect,
      onSubmit,
    },
  };
}

export default usePurchaseItemsContainer;
