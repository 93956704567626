import React from "react";

import deleteIcon from "../../../../assets/icon/delete_icon.png";
import IconButton from "../../../common/components/IconButton";

interface PHRDeleteButtonProps {
  onClick?: () => void;
}

function PHRDeleteButton({ onClick }: PHRDeleteButtonProps) {
  return (
    <IconButton
      icon={deleteIcon}
      fullWidth
      size="xl"
      onClick={onClick && onClick}
    />
  );
}

export default PHRDeleteButton;
