import React from "react";
import Button from "./Button";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import { BloodType } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";
import { BLOOD_TYPE } from "../../../utils/constants";

interface BloodTypeInputProps {
  label?: string | number;
  selectedBloodType: BloodType | null;
  onChange: (bloodType: BloodType) => void;
}

function BloodTypeInput({
  label,
  selectedBloodType,
  onChange,
}: BloodTypeInputProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      <Flex
        width="100%"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing[8]}
        flexWrap="wrap"
      >
        {Object.values(BLOOD_TYPE).map((type) => {
          const isSelected = selectedBloodType === type;
          return (
            <Button
              key={type}
              size="lg"
              text={type}
              variant={isSelected ? "contained" : "outline"}
              bgColor={isSelected ? "primary1" : "neutralWhite"}
              fontColor={isSelected ? "neutralWhite" : "neutral700"}
              borderColor={isSelected ? "primary1" : "neutral700"}
              onClick={() => onChange(type as BloodType)}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default BloodTypeInput;
