import React from "react";
import Flex from "../../../common/components/Flex";
import PHRPathname from "../../phr/component/PHRPathname";
import PHRAddButton from "../../phr/component/PHRAddButton";
import useVaccineContainer from "./VaccineContainer.hook";
import Loading from "../../../common/components/Loading";
import VaccineForm from "../components/VaccineForm";
import Button from "../../../common/components/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function VaccineContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data },
  } = useVaccineContainer();

  if (loading) return <Loading />;

  return (
    <>
      <PHRPathname name="vaccineTitle" />
      <VaccineForm vaccine={null} />
      {data?.map((disease) => (
        <VaccineForm key={disease?.id} vaccine={disease!} />
      ))}
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Button
          text={t("common.save")}
          size="lg"
          round={false}
          onClick={() => toast(t("common.saved"))}
        />
      </Flex>
    </>
  );
}

export default VaccineContainer;
