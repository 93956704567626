import React from "react";
import useHomeOnlineInstructors from "./HomeOnlineInstructors.hook";
import theme from "../../../lib/theme";
import Grid from "../../common/components/Grid";
import InstructorThumbnail from "../../common/components/InstructorThumbnail";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

function HomeOnlineInstructors() {
  const { isMobile } = useScreenWidthContext();

  const {
    models: { loading, data },
  } = useHomeOnlineInstructors();

  return (
    <Grid
      gridTemplateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"}
      gridAutoRows="auto"
      gap={theme.spacing[20]}
      width="100%"
    >
      {data?.map((instructor) => (
        <InstructorThumbnail
          key={instructor?.id}
          id={instructor?.id!}
          avatar={instructor?.user?.avatar?.uri}
          name={instructor?.user?.name || ""}
          job={instructor?.job}
          lastWorkAt={instructor?.experiences?.[0]?.place}
          online={instructor?.online}
        />
      ))}
    </Grid>
  );
}

export default HomeOnlineInstructors;
