import { useGetMyMedicinesQuery } from "../../../../lib/apollo/graphql/generated";

function useMedicineContainer() {
  const { loading, data } = useGetMyMedicinesQuery();

  return {
    models: {
      loading,
      data: data?.getMyMedicines,
    },
  };
}

export default useMedicineContainer;
