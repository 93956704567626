import { useGetMyBillingsQuery } from "../../../../lib/apollo/graphql/generated";

function useMyBillingContainer() {
  const { loading, data } = useGetMyBillingsQuery({
    fetchPolicy: "cache-and-network",
  });

  return {
    models: {
      loading,
      data: data?.getMyBillings,
    },
  };
}

export default useMyBillingContainer;
