import React from "react";
import Flex from "../../../common/components/Flex";
import theme from "../../../../lib/theme";
import InputLabel from "../../../common/components/InputLabel";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import Button from "../../../common/components/Button";

interface PHROptionSelectorProps {
  label: string;
  options?: { id: string; name: string; isSelected: boolean }[];
  onClick?: (id: string) => void;
}

function PHROptionSelector({
  label,
  options,
  onClick,
}: PHROptionSelectorProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={t("phr." + label)} />}
      <Flex
        alignItems="center"
        width="100%"
        gap={theme.spacing[isMobile ? 8 : 12]}
        flexWrap="wrap"
      >
        {options &&
          options?.map((option) => (
            <Button
              key={option?.id}
              text={option?.name}
              size="md"
              variant={option?.isSelected ? "contained" : "outline"}
              bgColor={option?.isSelected ? "primary1" : "neutralWhite"}
              fontColor={!option?.isSelected ? "primary1" : "neutralWhite"}
              onClick={() => onClick && onClick(option?.id)}
            />
          ))}
      </Flex>
    </Flex>
  );
}

export default PHROptionSelector;
