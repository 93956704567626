import React, { useEffect, useState } from "react";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import { useAuthContext } from "../../common/provider/AuthProvider";
import theme from "../../../lib/theme";
import dayjs from "dayjs";
import Avatar from "../../common/components/Avatar";
import Modal from "../../common/components/Modal";
import {
  ExtendSessionMutationVariables,
  GetMyUserProfileDocument,
  GetSessionDocument,
  SessionStatus,
  SubscriptionStatus,
  UserPassStatus,
  useExtendSessionMutation,
  useGetMyOwnUserPassesQuery,
  useGetMySubscriptionsQuery,
  useGetMyUserProfileQuery,
  useToggleSessionStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import Button from "../../common/components/Button";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import { useZoomContext } from "../provider/ZoomProvider";

interface SessionChatMessageProps {
  message: any;
}

function SessionChatMessage({ message }: SessionChatMessageProps) {
  const { profile } = useAuthContext();
  const navigate = useNavigate();
  const zoomClient = useZoomContext();

  const { data: userProfile } = useGetMyUserProfileQuery();

  const [showExtension, setShowExtension] = useState(true);

  const { loading: subscriptionLoading, data: subscription } =
    useGetMySubscriptionsQuery();

  const { loading: userPassLoading, data: userPass } =
    useGetMyOwnUserPassesQuery({
      variables: {
        first: 100,
        status: [UserPassStatus.Purchased, UserPassStatus.Received],
      },
    });

  const [extendSession] = useExtendSessionMutation({
    onCompleted: (data) => {
      if (data?.extendSession) {
        setShowExtension(false);
      }
    },
    refetchQueries: () => [
      {
        query: GetSessionDocument,
        variables: {
          sessionId: message?.session?.id,
        },
      },
      {
        query: GetMyUserProfileDocument,
      },
    ],
  });

  const [toggleSessionStatus] = useToggleSessionStatusAsInstructorMutation({
    onCompleted: async (data) => {
      if (
        data?.toggleSessionStatusAsInstructor?.status === SessionStatus.Done
      ) {
        await zoomClient.leave();

        navigate(SCREENS.HOME);

        window.location.reload();
      }
    },
  });

  const { user, body, createdAt } = message;

  const isMyMessage = user?.id === profile?.id;

  const isExtension = !!body && body === "1MIN_BEFORE_SESSION_END";

  const isFinish = !!body && body === "SESSION_FINISHED";

  const availablePasses = userPass?.getMyOwnUserPasses?.edges?.filter(
    (userPass) => !userPass?.sent
  );

  function onExtend(userPassId?: string) {
    const variables: ExtendSessionMutationVariables = {
      sessionId: message?.session?.id,
    };

    if (userPassId) {
      variables.userPassId = userPassId;
    } else {
      const activeSubscription = subscription?.getMySubscriptions?.find(
        (item) => item?.status === SubscriptionStatus.Active
      );

      if (!activeSubscription) return;

      variables.subscriptionId = activeSubscription?.id;
    }

    extendSession({
      variables,
    });
  }

  useEffect(() => {
    if (isFinish) {
      toggleSessionStatus({
        variables: {
          sessionId: message?.session?.id,
          status: SessionStatus.Done,
        },
      });
    }
  }, [isFinish]);

  if (isFinish) {
    return <div />;
  }

  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent={isMyMessage ? "flex-end" : "flex-start"}
      gap={theme.spacing[8]}
    >
      {!isMyMessage && (
        <Avatar
          uri={user?.avatar?.uri}
          initial={user?.name?.slice(0, 1)}
          size={32}
        />
      )}
      {isMyMessage && (
        <Text typography="label3" color={theme.color.neutral500}>
          {dayjs(createdAt).format("h:mm a")}
        </Text>
      )}
      {!isExtension ? (
        <Text
          typography="label2"
          borderTopLeftRadius={isMyMessage ? 0 : 20}
          borderTopRightRadius={isMyMessage ? 20 : 0}
          backgroundColor={
            !isMyMessage ? theme.color.neutral300 : theme.color.primary3
          }
          padding={`${theme.spacing[8]}px ${theme.spacing[24]}px`}
        >
          {body}
        </Text>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          gap={theme.spacing[4]}
          borderTopLeftRadius={isMyMessage ? 0 : 20}
          borderTopRightRadius={isMyMessage ? 20 : 0}
          backgroundColor={
            !isMyMessage ? theme.color.neutral300 : theme.color.primary3
          }
          padding={`${theme.spacing[8]}px ${theme.spacing[24]}px`}
        >
          <Text typography="label2">
            곧 상담이 종료됩니다.{" "}
            {showExtension
              ? userProfile?.getMyUserProfile?.isSubscriber
                ? userProfile?.getMyUserProfile?.usuableTickets > 0
                  ? "연장하시겠습니까?"
                  : availablePasses && availablePasses?.length > 0
                  ? "연장하시겠습니까?"
                  : ""
                : ""
              : ""}
          </Text>
          {showExtension
            ? ((userProfile?.getMyUserProfile?.isSubscriber &&
                userProfile?.getMyUserProfile?.usuableTickets) ||
                (availablePasses && availablePasses?.length > 0)) && (
                <Text typography="label2">
                  남은 이용권 갯수 :{" "}
                  {userProfile?.getMyUserProfile?.usuableTickets
                    ? userProfile?.getMyUserProfile?.usuableTickets
                    : availablePasses && availablePasses?.length}
                </Text>
              )
            : ""}
          {showExtension
            ? userProfile?.getMyUserProfile?.isSubscriber &&
              userProfile?.getMyUserProfile?.usuableTickets > 0 && (
                <Button size="lg" text="연장하기" onClick={onExtend} />
              )
            : null}
        </Flex>
      )}
      {!isMyMessage && (
        <Text typography="label3" color={theme.color.neutral500}>
          {dayjs(createdAt).format("h:mm a")}
        </Text>
      )}
    </Flex>
  );
}

export default SessionChatMessage;
