import React from "react";
import Layout from "../../common/components/Layout";
import HomeInstructorList from "../components/HomeInstructorList";
import HomeTopSection from "../components/HomeTopSection";
import HomeFaq from "../components/HomeFaq";

function HomeContainer() {
  return (
    <Layout>
      <HomeTopSection />
      <HomeInstructorList />
      <HomeFaq />
    </Layout>
  );
}

export default HomeContainer;
