import React from "react";
import useSessionContainer from "./SessionContainer.hook";
import Layout from "../../common/components/Layout";
import Loading from "../../common/components/Loading";
import Section from "../../common/components/Section";
import ZoomProvider from "../provider/ZoomProvider";
import Flex from "../../common/components/Flex";
import SessionUsers from "../components/SessionUsers";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import { useTranslation } from "react-i18next";
import Divider from "../../common/components/Divider";
import Spacer from "../../common/components/Spacer";
import SessionTopBar from "../components/SessionTopBar";
import SessionController from "../components/SessionController";
import SessionChat from "../components/SessionChat";
import SessionChatFooter from "../components/SessionChatFooter";
import SessionZoom from "../components/SessionZoom";
import { generateSignature } from "../helpers/generateSignature";

function SessionContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data, joined },
  } = useSessionContainer();

  if (loading) return <Loading />;

  if (!joined) return <Loading />;

  return (
    <ZoomProvider>
      <Section innerStyle={{ height: "90vh", position: "relative" }}>
        <SessionZoom
          session={data!}
          signature={generateSignature(
            data?.id!,
            data?.password!,
            "",
            "",
            1,
            "0",
            "",
            ""
          )}
        />
      </Section>
    </ZoomProvider>
  );
}

export default SessionContainer;
